<template>
  <slot name="label">
    <label v-if="label" :class="labelClasses">
      {{label}}
    </label>
  </slot>
  <div :class="[
       {'input-group': hasIcon},
       {'focused': focused},
       {'input-group-alternative': alternative},
       {'has-label': label || $slots.label},
       inputGroupClasses
       ]">
    <slot v-bind="slotData">
      <Field :name="name" :bails="bails" v-model="fieldValue" :rules="rules" v-slot="{ field, errors , meta  }">
        <div v-if="prependIcon || $slots.prepend" :class="errors.length ? 'err-msg' : ''"  class="input-group-prepend">
              <span class="input-group-text">
                <slot name="prepend">
                  <i :class="prependIcon"></i>
                </slot>
              </span>
        </div>
        <input
            v-bind="field"
            :type="type"
            :valid="meta.valid"
            :rules="rules"
            :placeholder="placeholder"
            :class="(errors.length ? 'err-msg' : '') + inputClasses"
            :disabled="disabled"
            :maxlength="maxlength"
            @focus="onInputFocus"
            @focusout="onInputFocusOut"
            @keyup="onKeyUp"
            @input="onInput"
            :readonly="readonly"
            class="form-control"/>

        <slot name="errors">
          <div  v-for="(error , index ) in errors" :key="index" class="invalid-feedback" style="display: block;">
            {{ error }}
          </div>
        </slot>
      </Field>
    </slot>
    <div v-if="appendIcon || $slots.append" class="input-group-append">
          <span class="input-group-text">
              <slot name="append">
                  <i :class="appendIcon"></i>
              </slot>
          </span>
    </div>
    <slot name="infoBlock"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)"
    },
    maxlength : {
      type: Number,
      description: "Max Length added",
      default: 500
    },
    group: {
      type: Boolean,
      description: "Whether input is an input group (manual override in special cases)"
    },
    disabled: {
      type: Boolean,
      description: "Whether input is an input group (manual override in special cases)",
      default: false
    },
    alternative: {
      type: Boolean,
      description: "Whether input is of alternative layout"
    },
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    error: {
      type: String,
      description: "Input error (below input)"
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: ''
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    inputGroupClasses: {
      type: String,
      description: "Input group css classes"
    },
    value: {
      type: [String, Number],
      description: "Input value"
    },
    modelValue: {
      type: [String, Number],
      description: "Input value"
    },
    placeholder: {
      type: [String, Number],
      description: "Please enter"
    },
    type: {
      type: String,
      description: "Input type",
      default: "text"
    },
    appendIcon: {
      type: String,
      description: "Append icon (right)"
    },
    prependIcon: {
      type: String,
      description: "Prepend icon (left)"
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: ''
    },
    bails: {
      type: Boolean,
      default: true,
      description: "If true, the validation will stop on the first failing rule."
    },
    readonly: {
      type: Boolean,
      default: false,
      description: "Read Only",
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners
      };
    },
    hasIcon() {
      const { append, prepend } = this.$slots;
      return (
          append !== undefined ||
          prepend !== undefined ||
          this.appendIcon !== undefined ||
          this.prependIcon !== undefined ||
          this.group
      );
    },
    fieldValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  methods: {
    onInputFocus(e) {
      this.$emit("onInputFocus", e);
    },
    onInputFocusOut(e) {
      this.$emit("onInputFocusOut", e);
    },
    onKeyUp(e) {
      this.$emit("onKeyUp", e);
    },
    onInput(e) {
      this.$emit("onInput", e);
    }
  }
};
</script>
<style>
</style>

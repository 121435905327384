<template>
  <Header></Header>
  <div id="main-wrapper">
    <Sidebar></Sidebar>
    <div id="content-wrapper">
      <div class="row m-0">
        <div class="col-md-12">
          <div class="bredcrumb mb-2" v-bind:class="'bread_' + $route.name">
            <slot name="brand">
              <router-link v-if="$route.name != 'dashboard'" :to="breadcrumbsUrl"
                class="h4 mb-0 bcumb-normal mr-2 d-lg-inline-block">Membership Hub</router-link>
              <span v-if="$route.name != 'dashboard'">/</span>
              <span v-for="(value, index) in breadcrumbs" :key="index">
                <router-link :to="value.link ?? ''"
                  :class="(index == Object.keys(breadcrumbs).length - 1) ? 'bcumb-active' : ''"
                  class="h4 mb-0 ml-2 d-lg-inline-block">{{ value.name }} {{ (index != Object.keys(breadcrumbs).length -
                    1) ? '/' : '' }}</router-link>
              </span>
            </slot>
          </div>
        </div>
      </div>

      <template v-if="this.checkAccess()">
        <Transition name="fade" mode="out-in">
          <router-view>
          </router-view>
        </Transition>
      </template>
      <template v-else>
        <PermissionDenied />
      </template>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from '@/shared/Header.vue';
import Footer from '@/shared/Footer.vue';
import Sidebar from '@/shared/Sidebar.vue';
import { FadeInOut } from 'vue3-transitions'

import PermissionDenied from "./PermissionDenied.vue"
import { refreshToken } from "@/keycloakAuth";

export default {
  name: "DashboardLayoutWrap",
  components: {
    FadeInOut,
    Header,
    Footer,
    Sidebar,
    PermissionDenied
  },
  watch: {
    $route(to, from) {
      this.breadcrumbs = this.$route.meta.breadcrumb;
    }
  },
  data() {
    return {
      breadcrumbs: this.$route.meta.breadcrumb,
      breadcrumbsUrl : '/dashboard'
    }
    keycloakLoader: true
  },

  methods: {
  },
  mounted() {
    this.emitter.on('SetBreadcrumb', (evt) => {
      this.breadcrumbs = Object.assign({}, evt.breadcrumb);
    });
    this.getUser();
  },
  beforeMount() {
    refreshToken();
    this.breadcrumbsUrl = window.location.hostname.split('.')[0] !== process.env.VUE_APP_SHOOTING_REALM ? '/dashboard'
        : '#';
  },
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}</style>


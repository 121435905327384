<template>
    <div class="main-wrapper bg-default">
      <div class="container-fluid">  
        <div class="row">
            <div class="col-md-2 mreg-sidebar">
<!--              <img :src="image" alt="isportz">-->
            </div>
            <div class="col-md-10 rightside_section">                
                <router-view /> 
            </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { FadeInOut } from 'vue3-transitions'
  import Logo from "../authpages/Logo.vue"
  import DeleteAccountImg from "../authpages/DeleteAccountImage.vue"
  
  
  export default {
    name: 'Registrationlayout',
    data(){
      return {
        image : 'isportz_reg_side_img.png'
      }
    },
    components: {
    //   Logo,
    //   FadeInOut,
    //   DeleteAccountImg
       },
    mounted() {
      var host = window.location.host;
      var image = host.split('.')[0]+'_reg_side_img.png';
       var imageUrl = '' ;
      try {
        imageUrl =  require(`../assets/img/General/${image}`)
      }catch{
        imageUrl = require(`../assets/img/General/isportz_reg_side_img.png`)
      }
      const sidebarElements = document.getElementsByClassName('mreg-sidebar');

      if (sidebarElements.length > 0) {
        sidebarElements[0].style.backgroundImage = `url(${imageUrl})`;
      }    }
  };
  </script>
  <style scoped>
  /* we will explain what these classes do next! */
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.9s ease;
  }
  
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  </style>
  
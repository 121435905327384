<template>
	<Header></Header>
	<div id="main-wrapper">
		<Sidebar></Sidebar>
		<div id="content-wrapper">
			<div class="row m-0">
				<div class="col-md-12">
					<div class="bredcrumb mb-2" v-bind:class="'bread_' + $route.name">
						<slot name="brand">
							<router-link v-if="$route.name != 'dashboard'" to="/dashboard"
								class="h4 mb-0 bcumb-normal mr-2 d-lg-inline-block">Dashboard</router-link>
							<span v-if="$route.name != 'dashboard'">/</span>
							<span v-for="(value, index) in breadcrumbs" :key="index">
								<router-link :to="value.link ?? ''"
									:class="(index == Object.keys(breadcrumbs).length - 1) ? 'bcumb-active' : ''"
									class="h4 mb-0 ml-2 d-lg-inline-block">{{ value.name }} {{ (index !=
										Object.keys(breadcrumbs).length - 1) ? '/' : '' }}</router-link>
							</span>
						</slot>
					</div>

					<div class="team_switcher" v-if="$route.name !=='RenewTeamWizard' && myTeams.length > 0 ">
						<label>Switch Team</label>
            <select class="form-control" v-model="myTeamId">
              <option v-for="(team, index) in myTeams" :key="index" :value="team.id">
                {{ team.name }}
              </option>
            </select>
					</div>
					
				</div>
			</div>
      <div v-if="isTeamsLoaded">
			<template v-if="this.checkAccess()">
				<Transition name="fade" mode="out-in">
					<router-view :myTeamId="myTeamId">
					</router-view>
				</Transition>
			</template>
			<template v-else>
				<PermissionDenied />
			</template>
        </div>
			
			<Footer></Footer>
		</div>
	</div>
</template>
  
<script>
import Header from '@/shared/Header.vue';
import Footer from '@/shared/Footer.vue';
import Sidebar from '@/shared/Sidebar.vue';
import { FadeInOut } from 'vue3-transitions'

import PermissionDenied from "./PermissionDenied.vue"
import { refreshToken } from "@/keycloakAuth";
import axios from "axios";

export default {
	name: "DashboardLayoutWrap",
	components: {
		FadeInOut,
		Header,
		Footer,
		Sidebar,
		PermissionDenied
	},
	watch: {
		$route(to, from) {
			this.breadcrumbs = this.$route.meta.breadcrumb;
		}
	},
	data() {
		return {
			breadcrumbs: this.$route.meta.breadcrumb,
			teamMenu:false,
      myTeams:{},
      myTeamId:'',
      isTeamsLoaded: false
		}
		keycloakLoader: true
	},
	methods: {
    getMyTeams(){
      axios.get(this.basePath + "api/getMyTeam", { headers: this.userHeader })
          .then((res) => {
            this.isTeamsLoaded = true;
            if (res.data.status === "success") {
              this.myTeams = res.data.data;
              if (this.myTeams.length > 0) {
                this.myTeamId = this.myTeams[0].id;
              }
            } else {
              // Handle unexpected response structure
              console.error('Unexpected response structure:', res.data);
            }
          })
          .catch((error) => {
            this.isTeamsLoaded = true;
            console.error('Error fetching teams:', error);
          });
    }
	},
	mounted() {
		this.emitter.on('SetBreadcrumb', (evt) => {
			this.breadcrumbs = Object.assign({}, evt.breadcrumb);
		});

		let proConfig=this.$store.getters.getConfig;
		if(proConfig && proConfig.teamMenu != undefined && proConfig.teamMenu === true){
			this.teamMenu=true
		}
	},
	beforeMount() {
		refreshToken();
	},
  created() {
    this.getMyTeams()
  }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.team_switcher {
    position: absolute;
    top: 22px;
    right: 22px;
    width: auto;
    display: flex;
    align-items: center;
}

.team_switcher label {
    margin: 0 10px 0;
    font-weight: bold;
    font-size: 14px;
	display: inline-block;
	width: 100%;
}
.team_switcher select .form-control{
	padding: 5px 2px 9px 11px;
}
/*.team_switcher select{
	min-width: 180px;
}*/

@media only screen and (max-width:768px){
.team_switcher {
	top:0;
	right:0;
	position: relative;
	background: #fff;
    padding: 5px;
    border: 0;
    box-shadow: 0px 3px 6px rgb(201 201 201 / 16%);
    margin: 15px auto 0 !important;
    border-radius: .375rem;
}
	
}
</style>
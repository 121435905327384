<template>
        <div class="spin" v-show="loader">
           <div class="ring"> 
<div class="loadingio-spinner-spin-cbbqv8bz8l"><div class="ldio-axnaqn13rn">
<div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
</div></div>
        </div>
        </div>
</template>

<script>
export default {
    setup() {
        
    },
    props: {
      loader: {
        type: Boolean,
        description: "Page Loader",
        default: false
      },
    }
}
</script>





<style type="text/css">
.spin {
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255);
    position: absolute;
    top: 1px;
}
.ring {
    position: relative;
    left: 50%;
    top: 50%;
    height: 100%;
    width: 60px;
}
@keyframes ldio-axnaqn13rn {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5,1.5);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.ldio-axnaqn13rn div > div {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #cc2121;
  animation: ldio-axnaqn13rn 1.2195121951219512s linear infinite;
}.ldio-axnaqn13rn div:nth-child(1) > div {
  left: 73px;
  top: 43px;
  animation-delay: -1.0452961672473868s;
}
.ldio-axnaqn13rn > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 80px 50px;
}.ldio-axnaqn13rn div:nth-child(2) > div {
  left: 62px;
  top: 66px;
  animation-delay: -0.8710801393728222s;
}
.ldio-axnaqn13rn > div:nth-child(2) {
  transform: rotate(51.42857142857143deg);
  transform-origin: 69px 73px;
}.ldio-axnaqn13rn div:nth-child(3) > div {
  left: 36px;
  top: 72px;
  animation-delay: -0.6968641114982578s;
}
.ldio-axnaqn13rn > div:nth-child(3) {
  transform: rotate(102.85714285714286deg);
  transform-origin: 43px 79px;
}.ldio-axnaqn13rn div:nth-child(4) > div {
  left: 16px;
  top: 56px;
  animation-delay: -0.5226480836236934s;
}
.ldio-axnaqn13rn > div:nth-child(4) {
  transform: rotate(154.28571428571428deg);
  transform-origin: 23px 63px;
}.ldio-axnaqn13rn div:nth-child(5) > div {
  left: 16px;
  top: 30px;
  animation-delay: -0.3484320557491289s;
}
.ldio-axnaqn13rn > div:nth-child(5) {
  transform: rotate(205.71428571428572deg);
  transform-origin: 23px 37px;
}.ldio-axnaqn13rn div:nth-child(6) > div {
  left: 36px;
  top: 14px;
  animation-delay: -0.17421602787456444s;
}
.ldio-axnaqn13rn > div:nth-child(6) {
  transform: rotate(257.14285714285717deg);
  transform-origin: 43px 21px;
}.ldio-axnaqn13rn div:nth-child(7) > div {
  left: 62px;
  top: 20px;
  animation-delay: 0s;
}
.ldio-axnaqn13rn > div:nth-child(7) {
  transform: rotate(308.57142857142856deg);
  transform-origin: 69px 27px;
}
.loadingio-spinner-spin-cbbqv8bz8l {
  width: 57px;
  height: 57px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-axnaqn13rn {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.57);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-axnaqn13rn div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>



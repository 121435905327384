<template>
  <div class="image-upload-package">
      <div class="d-flex align-items-center ml-1">
          <div  style="position:relative">
              <img  class="uplimg" :class="imgSrc == '' ? 'visible' : 'invisible'" :src="defaultImgSrc" @click="toggleShow" alt="profileupload" />
              <div class="cameraphoto">
                  <img class="uplimg" src="../assets/img/svg/camera.svg"  @click="toggleShow" alt="profileupload" />
              </div>
          </div>
          <div v-if="imgSrc == '' || removePhotoApiUrl == ''"><span>Upload Image</span></div>
          <div style="position: relative; top: -50px; z-index: 10; box-shadow: 1px 5px 10px #00000029; border-radius: 50%;" v-else><span @click="removeImage()" type="button" class="text-red ml-0"><img
                src="../assets/img/General/modal-close.svg"
                alt="modal_close" style="background: #fb5846; border-radius: 50%; width: 20px;"
            /></span></div>
      </div>
      <div class="upload-img-wrap mb-4 mypersonal ">
        <div class="uploadedimg" v-if="imgSrc != ''">
          <img :src="imgSrc" />
        </div>
    </div> 
    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="imageUploadModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg m-auto" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Upload Image</h5>
            <button type="button" class="close" 
             @click="closeModal">
              <span aria-hidden="true"><img src="../assets/img/General/modal-close.svg" alt="closeicon" /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="image-select-section m-auto text-center pt-3 pb-3" v-if="!fileSection">
              <a href="#" role="button" @click.prevent="showFileChooser">
              <img src="../assets/img/logo/uploadimg.png" alt="uploadimg" />
              </a>
              <input ref="input" type="file" :name="name" accept="image/*" @change="setImage"/>
              
              <div class="field-errors">
                  <ul v-for="(error, index) in this.errors" :key="index">
                      <li class="err-list">{{ error }}</li>
                  </ul>
              </div>
            </div>

          <div class="content" v-else>
            <section class="cropper-area">
              <div class="img-cropper">
                <vue-cropper
                  ref="cropper"
                  :aspect-ratio="1/1"
                  :src="imgSrcUpload"
                  preview=".preview"
                />
              </div>
              <div class="actions">
                <a class="btn-trans" :class="btn_processing ? 'disable-link': ''" href="#" role="button" @click.prevent="backToUploadImage"><span class="text-dark d-block">Back</span><img src="../assets/img/General/backico.svg" alt="back" /></a>
                <a  href="#" :class="btn_processing ? 'disable-link': ''" role="button"  @click.prevent="reset" ><span class="text-dark d-block">Reset</span><img src="../assets/img/General/reset.svg" alt="reset" /></a>
                <a href="#" :class="btn_processing ? 'disable-link': ''" role="button" @click.prevent="rotate(-90)" ><span class="text-dark d-block">Rotate</span> <img src="../assets/img/General/rotate.svg" alt="rotate" /></a>
                <a href="#" :class="btn_processing ? 'disable-link': ''" v-if="apiUrl != ''" role="button" @click.prevent="cropUploadImage" ><span class="text-dark d-block">Upload</span>
                  <i v-if="btn_processing" class="spinner-border spinner-border-sm text-light"></i>
                  <img v-else src="../assets/img/General/upload.svg" alt="upload" />
                </a>
              </div>
            </section>
            <section class="preview-area">
              <p>Preview</p>
              <div class="preview" style="width: 200px; height: 200px;" />
              <!-- <p>Cropped Image</p>
              <div class="cropped-image">
                <img
                  v-if="cropImg"
                  :src="cropImg"
                  alt="Cropped Image"
                />
                <div v-else class="crop-placeholder" />
              </div> -->
            </section>
          </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Cancel</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { boolean } from 'yup/lib/locale';
import { Buffer } from 'buffer';
export default {
  props: {
    name: {
      type: String,
      description: 'File Name',
      default: 'img'
    },
    apiUrl : {
      type: String,
      description: 'Axios file path',
      default: ''
    },
    removePhotoApiUrl : {
      type: String,
      description: 'remove uploaded image',
      default: ''
    },
    maxUploadSizeMB : {
      type: Number,
      description: 'Maximum File Upload Size',
      default: 2
    },
    imgSrc : {
      type: String,
      description: 'Display Image',
      default: ''
    },
    defaultImgSrc : {
      type: String,
      description: 'Default Image',
      default: require('../assets/img/svg/rectangle.svg')
    },
    apiSiteURL : {
      type: String,
      description: 'Service URL',
      default: ''
    },
    seccessAlert : {
      type : Boolean,
      description: 'Upload Seccess toast alert',
      default: true
    },
    params : {
      type : Object,
      description : 'Add the extra parametars',
      default : {}
    }
  },
  data() {
    return {
      imgSrcUpload: '',
      apiSiteURL : '',
      cropImg: '',
      data: null,
      fileSection : false,
      fileScrset : '',
      errors : [],
      imgSrc : this.imgSrc,
      btn_processing : false,
      fileExtensionType : 'image/jpeg'
    };
  },
  methods: {
      closeModal(){
        $("#imageUploadModal").modal('hide');
        this.clearFileUpload();
      },
      clearFileUpload(){
        this.fileSection = false;
        this.$refs.input.value = '';
        this.imgSrcUpload = '';
        this.errors = [];
      },
      toggleShow(){
          $("#imageUploadModal").modal('show');
      },
      backToUploadImage(){
        this.clearFileUpload();
      },
      validateUploadFile(file){
        const fileSize = file.size / 1024 / 1024; // in MiB
        let isValid = true;
        this.errors = [];
        if( fileSize >= this.maxUploadSizeMB) {
          this.errors.push('File is too big, please upload a file less than ' +this.maxUploadSizeMB+'MB');
          isValid = false;
        }
        const validImageTypes = ['image/jpeg', 'image/jpg','image/png'];
        if (!validImageTypes.includes(file.type)) {
          this.errors.push('Please upload a valid file format');
          isValid = false;
        }
        this.fileExtensionType = file.type;
        return isValid;
      },
      removeImage(){
          this.$swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
              if (result.isConfirmed) {
                    axios.delete(this.apiSiteURL +this.removePhotoApiUrl, { 
                      headers: this.userHeader 
                  }).then(response => {
                    this.imgSrc = '';
                      this.toast.success("Image Removed Successfully.", {
                          timeout: 3000
                      });
                      this.$emit('removeResponse');
                  })
                  .catch((error) => {
                      //
                  });
              }
          });
          return false;
      },
      dataUrlToFile(dataUrl, filename) {
        const arr = dataUrl.split(',');
        if (arr.length < 2) { return undefined; }
        const mimeArr = arr[0].match(/:(.*?);/);
        if (!mimeArr || mimeArr.length < 2) { return undefined; }
        const mime = mimeArr[1];
        const buff = Buffer.from(arr[1], 'base64');
        return new File([buff], filename, {type:mime});
      },
      cropUploadImage() {
        this.btn_processing = true;
        this.cropImg = this.$refs.cropper.getCroppedCanvas();
        let file = this.dataUrlToFile(this.cropImg.toDataURL(), 'fileName.jpg');
        let formData = new FormData();
        if(Object.keys(this.params).length){
          Object.entries(this.params).forEach(([key, value]) => {
            formData.append(key ,value );
          });
        }
          formData.append(this.name, file);
          axios.post(this.apiSiteURL+this.apiUrl, formData, { headers : this.userHeader} )
          .then(response => {
            const resp = response.data.data;
            this.imgSrc = this.apiSiteURL+'/'+resp.personal_details.head_shot;

            if(this.seccessAlert){
              this.toast.success("Image Uploaded Successfully.", {
                  timeout: 3000
              });
            }
            this.$emit('uploadResponse' , resp);
            this.closeModal();
   
          }).catch(err => {
            
          }).finally(()=> {
            this.btn_processing = false;
          });

      return false;
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute('data-scale', scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute('data-scale', scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];
      if(!this.validateUploadFile(file)){
        return false;
      }
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrcUpload = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
        this.fileSection = true;
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
  computed: {
      showUploadCropSection(){
          if(this.fileScrset == ''){
              return false;
          }
          return true;
      }
  },
  mounted(){
    console.log('imgSrc', this.imgSrc);
    this.apiSiteURL = this.basePath;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.image-upload-package input[type="file"] {
  display: none;
}
.image-upload-package .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}
.image-upload-package .header h2 {
  margin: 0;
}
.image-upload-package .header a {
  text-decoration: none;
  color: black;
}
.image-upload-package .modal-body{
  padding:5% 8%;
}
.image-upload-package .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 25px 0; */
}
.image-upload-package .cropper-area {
  /* width:100%; */
  width: 614px;
}
.image-upload-package .preview-area {
  /* max-width: 124px; */
 /*height:125px; */
  width:307px;
  margin-top: -75px;
}
.image-upload-package .actions {
  margin-top: 1rem;
  text-align: center;
}
.image-upload-package .actions a span {
    color: #00327f !important;
}
.image-upload-package .actions a {
  display: inline-block;
  padding: 5px 15px;
  /* background: #0062CC; */
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.image-upload-package .preview-area p {
  font-size: 18px;
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
  color: #000;
}
.image-upload-package .preview-area p:last-of-type {
  margin-top:0;

}
.image-upload-package .preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.image-upload-package .crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.image-upload-package .cropped-image img {
  max-width: 100%;
}
.image-upload-package .modal-title {
    font-size: 20px;
    font-weight: 500;
}

.modal-header {
    padding: 10px;
}

.image-upload-package  .modal-footer {
    border: none;
}
.preview-area .preview {
    border-radius: 20px;
    border: 3px solid #e5e5e5;
    margin: 0 auto;
}
/* .cropper-crop {
    border-radius: 25px;
} */
/* 
.preview img {
    padding: 10px !important;

    border-radius: 15px;
} */
.cropper-modal,
.cropper-container{
  border-radius: 25px;
}
.cropper-modal {
    /* background-color: #000; */
    opacity: 0.5;
    border: 2px dashed #d5d5d5;
    padding: 10px !important;
}
.cropper-bg {
   background: #fff !important;
}
.cropper-canvas,
.preview-area .preview img {
    border-radius: 25px !important;
}
.modal-dialog-centered {
    min-height: calc(100% - 0rem) !important;
}
</style>
import { createApp } from 'vue'
import axios from "axios";
import { ref } from 'vue'
import { useToast } from "vue-toastification";
import moment from 'moment-timezone';
import { encryptStorage } from "@/lib/encrypted-storage";
import keycloak from "@/keycloak";

export default {
    data() {
        var protocol = process.env.VUE_APP_HTTP_PROTOCOL ? process.env.VUE_APP_HTTP_PROTOCOL : 'https://';
        var subdomain = process.env.VUE_APP_REALM ? process.env.VUE_APP_REALM : window.location.host;
        var baseUrl = process.env.VUE_APP_URL ? process.env.VUE_APP_URL : protocol + subdomain + '/' + process.env.VUE_APP_BACKEND_SLUG;
        var emsBaseUrl = process.env.VUE_APP_EMS_URL ? process.env.VUE_APP_EMS_URL : protocol + subdomain + '/' + process.env.VUE_APP_EMS_BACKEND_SLUG;
        var emsFrontBaseUrl = process.env.VUE_APP_EMS_URL ? process.env.VUE_APP_EMS_URL : protocol + subdomain + '/' + process.env.VUE_APP_EMS_FRONTEND_SLUG;
        return {
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'link',
                        // 'bulletedList',
                        'numberedList',
                        '|',
                        'alignment',
                        'outdent',
                        'indent',
                        '|',
                        'fontSize',
                        'fontColor',
                        'fontBackgroundColor',
                        'fontFamily',
                        // 'highlight',
                        // 'code',
                        // 'codeBlock',
                        // 'blockQuote',
                        // 'insertTable',
                        // 'mediaEmbed',
                        'undo',
                        'redo',
                        'sourceEditing',
                        // 'imageInsert',
                        // 'imageUpload',
                        // 'style'
                    ]
                }
            },
            http: axios,
            basePath: baseUrl,
            emsBasePath: emsBaseUrl,
            emsFrontBasePath: emsFrontBaseUrl,
            emailCampaignBasePath: process.env.VUE_APP_EMAIL_CAMPAIGN_URL,
            googleRecaptcha: process.env.VUE_APP_RECAPTCHA_KEY,
            header: {
                "Accept": "application/json",
            },
            campaignHeaders: {
                "application-id": process.env.VUE_APP_APPLICATION_ID,
                "Accept": "application/json",
                "ClientId": 4
            },
            userHeader: {
                "Accept": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'ClientId': localStorage.getItem('ClientId'),
                'team': encryptStorage.getItem('team') ? encryptStorage.getItem('team').id : '',
                'userType': encryptStorage.getItem('user_type')
            },
            eventsHeader: {
                "Accept": "application/json",
                'access-token': 'jiSacF86To9Lvwfg6lYyz2c0p0yQJhH',
            },
            fileUserHeader: {
                'Authorization': 'Bearer ' + localStorage.getItem('userToken'),
                'Content-Type': 'multipart/form-data',
                'team': encryptStorage.getItem('team') ? encryptStorage.getItem('team').id : ''
            },
            importFileHeader: {
                "Accept": 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'ClientId': localStorage.getItem('ClientId'),
                'team': encryptStorage.getItem('team') ? encryptStorage.getItem('team').id : '',
                'userType': encryptStorage.getItem('user_type')
            },
            authUserDetails: encryptStorage.getItem('user'),
            btn_processing: false,
            exceptRoutes: ['rsvpinvitation'],
            ClientId: localStorage.getItem('ClientId'),
            formButtonSpinner: '<i class="spinner-border spinner-border-sm text-light"></i>',
            globalPageLoader: false,
            toast: useToast(),
            relation_types: [],
            userNotifyListing: [],
            loginExpirationDuration: 30,
            listOfCountries: [
                { country: 'USA' },
                { country: 'Canada' },
                { country: 'Afghanistan' },
                { country: 'Åland Islands' },
                { country: 'Albania' },
                { country: 'Algeria' },
                { country: 'American Samoa' },
                { country: 'AndorrA' },
                { country: 'Angola' },
                { country: 'Anguilla' },
                { country: 'Antarctica' },
                { country: 'Antigua and Barbuda' },
                { country: 'Argentina' },
                { country: 'Armenia' },
                { country: 'Aruba' },
                { country: 'Australia' },
                { country: 'Austria' },
                { country: 'Azerbaijan' },
                { country: 'Bahamas' },
                { country: 'Bahrain' },
                { country: 'Bangladesh' },
                { country: 'Barbados' },
                { country: 'Belarus' },
                { country: 'Belgium' },
                { country: 'Belize' },
                { country: 'Benin' },
                { country: 'Bermuda' },
                { country: 'Bhutan' },
                { country: 'Bolivia' },
                { country: 'Botswana' },
                { country: 'Brazil' },
                { country: 'Britain' },
                { country: 'British West Indies' },
                { country: 'Brunei Darussalam' },
                { country: 'Bulgaria' },
                { country: 'Burma' },
                { country: 'Burundi' },
                { country: 'Cambodia' },
                { country: 'Cameroon' },
                { country: 'Cayman Islands' },
                { country: 'Central African Rep.' },
                { country: 'Chad' },
                { country: 'Chile' },
                { country: 'China' },
                { country: 'Colombia' },
                { country: 'Congo' },
                { country: 'Costa Rica' },
                { country: 'Cuba' },
                { country: 'Cyprus' },
                { country: 'Czech Republic' },
                { country: 'Czechoslovakia' },
                { country: 'Denmark' },
                { country: 'Dominican Republic' },
                { country: 'Ecuador' },
                { country: 'Egypt' },
                { country: 'El Salvador' },
                { country: 'England' },
                { country: 'Equatorial Guinea' },
                { country: 'Estonia' },
                { country: 'Ethiopia' },
                { country: 'Falkland Islands' },
                { country: 'Faroe Islands' },
                { country: 'Finland' },
                { country: 'France' },
                { country: 'French West Indies' },
                { country: 'Gabon' },
                { country: 'Gambia' },
                { country: 'Georgia' },
                { country: 'Germany' },
                { country: 'Ghana' },
                { country: 'Gibraltar' },
                { country: 'Great Britain' },
                { country: 'Greece' },
                { country: 'Greenland' },
                { country: 'Grenada' },
                { country: 'Guadeloupe' },
                { country: 'Guam' },
                { country: 'Guatemala' },
                { country: 'Guinea' },
                { country: 'Guyana' },
                { country: 'Haiti' },
                { country: 'Honduras' },
                { country: 'Hong Kong' },
                { country: 'Hungary' },
                { country: 'Iceland' },
                { country: 'India' },
                { country: 'Indonesia' },
                { country: 'Iran' },
                { country: 'Iraq' },
                { country: 'Iraq-Saudi Arabia Neutra' },
                { country: 'Ireland' },
                { country: 'Israel' },
                { country: 'Italy' },
                { country: 'Ivory Coast' },
                { country: 'Jamaica' },
                { country: 'Japan' },
                { country: 'Jordan' },
                { country: 'Kazakhstan' },
                { country: 'Kenya' },
                { country: 'Korea' },
                { country: 'Kuwait' },
                { country: 'Kyrgyzstan' },
                { country: 'Laos' },
                { country: 'Latvia' },
                { country: 'Lebanon' },
                { country: 'Lesotho' },
                { country: 'Liberia' },
                { country: 'Libya' },
                { country: 'Liechtenstein' },
                { country: 'Lithuania' },
                { country: 'Luxembourg' },
                { country: 'Macau' },
                { country: 'Madagascar' },
                { country: 'Malaysia' },
                { country: 'Mali' },
                { country: 'Malta' },
                { country: 'Mauritania' },
                { country: 'Mauritius' },
                { country: 'Mexico' },
                { country: 'Moldova' },
                { country: 'Monaco' },
                { country: 'Morocco' },
                { country: 'Mozambique' },
                { country: 'Namibia' },
                { country: 'Nepal' },
                { country: 'Netherlands' },
                { country: 'Netherlands Antilles' },
                { country: 'New Zealand' },
                { country: 'Nicaragua' },
                { country: 'Niger' },
                { country: 'Nigeria' },
                { country: 'North Korea' },
                { country: 'Northern Ireland' },
                { country: 'Norway' },
                { country: 'Oman' },
                { country: 'Pakistan' },
                { country: 'Palau' },
                { country: 'Panama' },
                { country: 'Papua New Guinea' },
                { country: 'Paraguay' },
                { country: 'Peru' },
                { country: 'Philippines' },
                { country: 'Poland' },
                { country: 'Portugal' },
                { country: 'Qatar' },
                { country: 'Romania' },
                { country: 'Russia' },
                { country: 'Rwanda' },
                { country: 'Saint Helena' },
                { country: 'Saint Vincent' },
                { country: 'San Marino' },
                { country: 'Saudi Arabia' },
                { country: 'Senegal' },
                { country: 'Serbia' },
                { country: 'Sierra Leone' },
                { country: 'Singapore' },
                { country: 'Slovakia' },
                { country: 'Somalia' },
                { country: 'South Africa' },
                { country: 'South Korea' },
                { country: 'Spain' },
                { country: 'Sri Lanka' },
                { country: 'Sudan' },
                { country: 'Suriname' },
                { country: 'Swaziland' },
                { country: 'Sweden' },
                { country: 'Switzerland' },
                { country: 'Syria' },
                { country: 'Tahiti' },
                { country: 'Taiwan' },
                { country: 'Tajikistan' },
                { country: 'Tanzania' },
                { country: 'Thailand' },
                { country: 'Togo' },
                { country: 'Trinidad West Indies' },
                { country: 'Tunisia' },
                { country: 'Turkey' },
                { country: 'Turkmenistan' },
                { country: 'Uganda' },
                { country: 'Ukraine' },
                { country: 'United Arab Emirates' },
                { country: 'United Kingdom' },
                { country: 'Uruguay' },
                { country: 'Uzbekistan' },
                { country: 'Venezuela' },
                { country: 'Vietnam' },
                { country: 'Virgin Islands' },
                { country: 'Wales' },
                { country: 'Yemen' },
                { country: 'Yugoslavia' },
            ],
            listOfStatesInUS: [
                { state_code: 'AL', state: 'Alabama' },
                { state_code: 'AK', state: 'Alaska' },
                { state_code: 'AZ', state: 'Arizona' },
                { state_code: 'AR', state: 'Arkansas' },
                { state_code: 'CA', state: 'California' },
                { state_code: 'CO', state: 'Colorado' },
                { state_code: 'CT', state: 'Connecticut' },
                { state_code: 'DE', state: 'Delaware' },
                { state_code: 'DC', state: 'District of Columbia' },
                { state_code: 'FL', state: 'Florida' },
                { state_code: 'GA', state: 'Georgia' },
                { state_code: 'HI', state: 'Hawaii' },
                { state_code: 'ID', state: 'Idaho' },
                { state_code: 'IL', state: 'Illinois' },
                { state_code: 'IN', state: 'Indiana' },
                { state_code: 'IA', state: 'Iowa' },
                { state_code: 'KS', state: 'Kansas' },
                { state_code: 'KY', state: 'Kentucky' },
                { state_code: 'LA', state: 'Louisiana' },
                { state_code: 'ME', state: 'Maine' },
                { state_code: 'MD', state: 'Maryland' },
                { state_code: 'MA', state: 'Massachusetts' },
                { state_code: 'MI', state: 'Michigan' },
                { state_code: 'MN', state: 'Minnesota' },
                { state_code: 'MS', state: 'Mississippi' },
                { state_code: 'MO', state: 'Missouri' },
                { state_code: 'MT', state: 'Montana' },
                { state_code: 'NE', state: 'Nebraska' },
                { state_code: 'NV', state: 'Nevada' },
                { state_code: 'NH', state: 'New Hampshire' },
                { state_code: 'NJ', state: 'New Jersey' },
                { state_code: 'NM', state: 'New Mexico' },
                { state_code: 'NY', state: 'New York' },
                { state_code: 'NC', state: 'North Carolina' },
                { state_code: 'ND', state: 'North Dakota' },
                { state_code: 'OH', state: 'Ohio' },
                { state_code: 'OK', state: 'Oklahoma' },
                { state_code: 'OR', state: 'Oregon' },
                { state_code: 'PA', state: 'Pennsylvania' },
                { state_code: 'RI', state: 'Rhode Island' },
                { state_code: 'SC', state: 'South Carolina' },
                { state_code: 'SD', state: 'South Dakota' },
                { state_code: 'TN', state: 'Tennessee' },
                { state_code: 'TX', state: 'Texas' },
                { state_code: 'UT', state: 'Utah' },
                { state_code: 'VT', state: 'Vermont' },
                { state_code: 'VA', state: 'Virginia' },
                { state_code: 'WA', state: 'Washington' },
                { state_code: 'WV', state: 'West Virginia' },
                { state_code: 'WI', state: 'Wisconsin' },
                { state_code: 'WY', state: 'Wyoming' },
            ],
            listOfStatesInCanada: [
                { state_code: 'AB', state: 'Alberta' },
                { state_code: 'BC', state: 'British Columbia' },
                { state_code: 'MB', state: 'Manitoba' },
                { state_code: 'NB', state: 'New Brunswick' },
                { state_code: 'NF', state: 'Newfoundland' },
                { state_code: 'NT', state: 'Northwest Territories' },
                { state_code: 'NS', state: 'Nova Scotia' },
                { state_code: 'NU', state: 'Nunavut' },
                { state_code: 'ON', state: 'Ontario' },
                { state_code: 'PE', state: 'Prince Edward Island' },
                { state_code: 'PQ', state: 'Quebec' },
                { state_code: 'SK', state: 'Saskatchewan' },
                { state_code: 'YT', state: 'Yukon' },
            ],
            listOfTimeZoneInUSAndCanada: [
                /*{ TimeZone: 'ET' },*/
                // { TimeZone: 'EST' },
                // { TimeZone: 'EDT' },
                /*{ TimeZone: 'CT' },*/
                // { TimeZone: 'CST' },
                // { TimeZone: 'CDT' },
                /*{ TimeZone: 'MT' },*/
                // { TimeZone: 'MST' },
                // { TimeZone: 'MDT' },
                /*{ TimeZone: 'PT' },*/
                // { TimeZone: 'PST' },
                // { TimeZone: 'PDT' },
                /*{ TimeZone: 'AK' },*/
                // { TimeZone: 'AKST' },
                // { TimeZone: 'AKDT' },
                /*{ TimeZone: 'HT' },*/
                // { TimeZone: 'HST' },
                // { TimeZone: 'HDT' },
                { id: 'Eastern Time', text: 'Eastern Time' },
                { id: 'Central Time', text: 'Central Time' },
                { id: 'Mountain Time', text: 'Mountain Time' },
                { id: 'Pacific Time', text: 'Pacific Time' },
                { id: 'Alaska Time', text: 'Alaska Time' },
                { id: 'Hawaii Time', text: 'Hawaii Time' },
                { id: 'UTC', text: 'UTC' }
            ],
            ageDivision: [
                { "text": "18O" },
                { "text": "18U" },
                { "text": "16U" },
            ],
            langExt: {
                hint: 'Please select the image with 250 x 250 px size for better result. Supported file format: JPG, JPEG, PNG',
                loading: 'Uploading...',
                noSupported: 'Browser is not supported, please use IE10+ or other browsers',
                success: 'Upload success',
                fail: 'Upload failed',
                preview: 'Preview',
                btn: {
                    off: 'Cancel',
                    close: 'Close',
                    back: 'Back',
                    save: 'Upload'
                },
                error: {
                    onlyImg: 'Image only',
                    outOfSize: 'Image size should be maximum: ',
                    lowestPx: 'Image\'s size is too low. Expected at least: '
                }
            },
            usStatsMultiselect: [{
                group: 'All US states',
                options: [
                    { id: 'AL', text: 'Alabama' },
                    { id: 'AK', text: 'Alaska' },
                    { id: 'AZ', text: 'Arizona' },
                    { id: 'AR', text: 'Arkansas' },
                    { id: 'CA', text: 'California' },
                    { id: 'CO', text: 'Colorado' },
                    { id: 'CT', text: 'Connecticut' },
                    { id: 'DE', text: 'Delaware' },
                    { id: 'DC', text: 'District of Columbia' },
                    { id: 'FL', text: 'Florida' },
                    { id: 'GA', text: 'Georgia' },
                    { id: 'HI', text: 'Hawaii' },
                    { id: 'ID', text: 'Idaho' },
                    { id: 'IL', text: 'Illinois' },
                    { id: 'IN', text: 'Indiana' },
                    { id: 'IA', text: 'Iowa' },
                    { id: 'KS', text: 'Kansas' },
                    { id: 'KY', text: 'Kentucky' },
                    { id: 'LA', text: 'Louisiana' },
                    { id: 'ME', text: 'Maine' },
                    { id: 'MD', text: 'Maryland' },
                    { id: 'MA', text: 'Massachusetts' },
                    { id: 'MI', text: 'Michigan' },
                    { id: 'MN', text: 'Minnesota' },
                    { id: 'MS', text: 'Mississippi' },
                    { id: 'MO', text: 'Missouri' },
                    { id: 'MT', text: 'Montana' },
                    { id: 'NE', text: 'Nebraska' },
                    { id: 'NV', text: 'Nevada' },
                    { id: 'NH', text: 'New Hampshire' },
                    { id: 'NJ', text: 'New Jersey' },
                    { id: 'NM', text: 'New Mexico' },
                    { id: 'NY', text: 'New York' },
                    { id: 'NC', text: 'North Carolina' },
                    { id: 'ND', text: 'North Dakota' },
                    { id: 'OH', text: 'Ohio' },
                    { id: 'OK', text: 'Oklahoma' },
                    { id: 'OR', text: 'Oregon' },
                    { id: 'PA', text: 'Pennsylvania' },
                    { id: 'RI', text: 'Rhode Island' },
                    { id: 'SC', text: 'South Carolina' },
                    { id: 'SD', text: 'South Dakota' },
                    { id: 'TN', text: 'Tennessee' },
                    { id: 'TX', text: 'Texas' },
                    { id: 'UT', text: 'Utah' },
                    { id: 'VT', text: 'Vermont' },
                    { id: 'VA', text: 'Virginia' },
                    { id: 'WA', text: 'Washington' },
                    { id: 'WV', text: 'West Virginia' },
                    { id: 'WI', text: 'Wisconsin' },
                    { id: 'WY', text: 'Wyoming' },
                ]

            }],
            defaultTeamImage: require('./assets/img/svg/default-team.svg'),
            membershipList: [],
            listOfGraduationYears: [

                { year: '1981' },
                { year: '1982' },
                { year: '1983' },
                { year: '1984' },
                { year: '1985' },
                { year: '1986' },
                { year: '1987' },
                { year: '1988' },
                { year: '1989' },
                { year: '1990' },
                { year: '1991' },
                { year: '1992' },
                { year: '1993' },
                { year: '1994' },
                { year: '1995' },
                { year: '1996' },
                { year: '1997' },
                { year: '1998' },
                { year: '1999' },
                { year: '2000' },
                { year: '2001' },
                { year: '2002' },
                { year: '2003' },
                { year: '2004' },
                { year: '2005' },
                { year: '2006' },
                { year: '2007' },
                { year: '2008' },
                { year: '2009' },
                { year: '2010' },
                { year: '2011' },
                { year: '2012' },
                { year: '2013' },
                { year: '2014' },
                { year: '2015' },
                { year: '2016' },
                { year: '2017' },
                { year: '2018' },
                { year: '2019' },
                { year: '2020' },
                { year: '2021' },
                { year: '2022' },
                { year: '2023' },
                { year: '2024' },
                { year: '2025' },
                { year: '2026' },
                { year: '2027' },
                { year: '2028' },
                { year: '2029' },
                { year: '2030' },
                { year: '2031' },
                { year: '2032' },
                { year: '2033' },
                { year: '2034' },
                { year: '2035' },
                { year: '2036' },
                { year: '2037' },
                { year: '2038' },
                { year: '2039' },
                { year: '2040' },
                { year: '2041' },
                { year: '2042' },
                { year: '2043' },
            ],
            teamList: [],
            teamOptions: [],
            loginUser: '',
            role: '',
            privacyPolicy: '',
            keycloakUserInfo: [],
            termsAndConditions: '',
            isLoadingContent: true,
            clientMenuAdmin: [],
            clientMenuMember: [],
            clientMenuClub: [],
            landingPageClientContent: [],
            graduationYearArr: [],
            jerseyNumberArr: [],
            addressFields: ['address1', 'address2', 'city', 'country', 'state', 'zip'],
            collegeOptions: [],
            memberLeague: [],
            afp_listOfCountries: [
                { country: 'USA' },
                { country: 'Canada' }
            ],
            afp_listOfStatesInCanada: [
                { state_code: 'AB', state: 'Alberta' },
                { state_code: 'BC', state: 'British Columbia' },
                { state_code: 'ON', state: 'Ontario' }
            ],
            devalliance_listOfCountries: [
                { country: 'USA' },
                { country: 'Canada' }
            ],
            devalliance_listOfStatesInCanada: [
                { state_code: 'AB', state: 'Alberta' },
                { state_code: 'BC', state: 'British Columbia' },
                { state_code: 'ON', state: 'Ontario' }
            ],
            client: '',
            organizedClients: ['alliance', 'devalliance', 'localafp'],
            loginPageNavigation: false,
            mmsClientLogo:'',
            customRealm: '',
        }
    },
    methods: {
        showButtonSpinner() {
            return '<i class="fas fa-spinner fa-spin"></i>';
        },
        logout() {
            let userToken = localStorage.getItem("userToken");
            localStorage.removeItem('userToken');
            this.$router.push('/login');
            axios
                .get(this.basePath + "api/logout", {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + userToken
                    }
                })
                .then((response) => {
                    localStorage.removeItem('user');
                    localStorage.removeItem('team');
                    localStorage.removeItem('user_type');
                    localStorage.removeItem('message_notify');
                    localStorage.removeItem('time');
                    localStorage.removeItem('remember_me');
                })
                .catch((error) => {

                })
                .finally(() => {
                    localStorage.removeItem('time');
                });
        },
        getStatusColor(status) {
            let color = ""
            if (status == "Active") {
                color = "g-dot"
            } else if (status == "Inactive") {
                color = "gr-dot"
            } else if (status == "Expired") {
                color = "y-dot"
            } else if (status == "Suspended") {
                color = "r-dot"
            } else if (status == "Pending") {
                color = "b-dot"
            } else {
                color = ""
            }
            return color;
        },
        capitalizeFirstLetter(str) {
            return str[0].toUpperCase() + str.slice(1);
        },
        updatePageLoading() {
            this.globalPageLoader = this.$globalPageLoader.value
        },
        showPageLoader(value) {
            this.$globalPageLoader.value = value;
        },
        formatDate(date, format = 'YYYY-MM-DD') {
            if(date !=''){
                return moment(date).format(format);
            }
            return '';
        },
        formatTime12(time, format = 'HH:mm:ss', second = false, meridiem = '') {
            return moment(time, format).format("hh:mm" + (second ? ':ss' : '') + (meridiem ? ' ' + meridiem : ''))
        },
        formatTime24(time, format = 'HH:mm:ss', second = false) {
            return moment(time, format).format("HH:mm" + (second ? ':ss' : ''))
        },
        formatDateTime(date, format = 'YYYY-MM-DD HH:mm:ss') {
            return moment(date).format(format);
        },
        isDateExpired(date, format = 'YYYY-MM-DD') {
            const currentDate = moment().format(format);
            if (moment(date).isBefore(currentDate)) {
                return true;
            } else {
                return false;
            }
        },
        fetchRelationTypes() {
            axios.get(this.basePath + "api/relation-types", {
                    headers: this.userHeader,
                }).then((response) => {
                    const resp = response.data.data;
                    this.relation_types = resp;
                })
                .catch((error) => {
                    //
                });
        },
        fetchLoginUserDetails(token = '') {
            let user_token = localStorage.getItem("userToken") == null ? token : localStorage.getItem("userToken");
            axios.get(this.basePath + "api/user/basic-details", {
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + user_token
                    },
                }).then((response) => {
                    const resp = response.data.data;
                    localStorage.setItem("user", JSON.stringify(resp));
                    this.emitter.emit('updateWaiverTab', { 'isWaiverEnabled': resp.is_type_account });
                })
                .catch((error) => {
                    //
                });
        },

        checkAccess() {
            let meta = this.$route.meta.can;
            let permissions = JSON.parse(JSON.stringify(this.$store.getters.fetchUserPermissions))
            // console.log(meta, permissions);
            if (permissions != '' && permissions != undefined && permissions.indexOf(meta) === -1) {
                return false
            }
            return true
        },

        can(role = null, permission = null) {
            let roles = JSON.parse(JSON.stringify(this.$store.getters.fetchUserRoles))
            let permissions = JSON.parse(JSON.stringify(this.$store.getters.fetchUserPermissions))
            if (roles != undefined && roles.indexOf(role) !== -1 || permissions != undefined && permissions.indexOf(permission) !== -1) {
                return true
            }
            return false
        },
        paymentMode($mode) {
            return ($mode != '' && $mode != null ? ($mode == 1 ? 'Online' : ($mode == 2 ? 'Offline' : '-')) : '-');
        },
        logoutSession() {
            this.loginTime = localStorage.getItem('time');
            let nowTime = moment().unix();
            let sessionValue = (this.loginExpirationDuration * 60);
            if (this.loginTime != null && this.$route.name != undefined && this.$route.name != '' && !this.exceptRoutes.includes(this.$route.name)) {
                if ((nowTime - this.loginTime) >= sessionValue) {
                    if (localStorage.getItem("userToken")) {
                        this.logout();
                    }
                } else {
                    localStorage.setItem('time', nowTime);
                }
            }
        },
        truncateString(str, n = 20) {
            if (str !== null && str !== undefined && str.length > n) {
                return str.substring(0, n) + "..";
            } else {
                return str;
            }
        },
        addBreadcrumb(value = {}) {
            if (Object.keys(this.$route.meta.breadcrumb).length && Object.keys(value).length) {
                let breadcrumbs = [...this.$route.meta.breadcrumb, ...value];
                this.emitter.emit('SetBreadcrumb', { 'breadcrumb': breadcrumbs });
            } else {
                this.emitter.emit('SetBreadcrumb', { 'breadcrumb': this.$route.meta.breadcrumb });
            }
        },
        numberKeyPress(event) {
            return (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(event.key) < 0 ? event.preventDefault() : true);
        },
        isClubTrialAccount() {
            return this.authUserDetails.club_trail_account;
        },
        getMembership(params) {
            axios.post(this.basePath + "api/admin/get_membership", { is_params: params }, {
                headers: this.userHeader,
            }).then((response) => {
                this.membershipList = response.data.data;
            }).catch((error) => {
                //
            });
        },
        getConfigData() {
            let client = this.client = window.location.hostname.split('.')[0];
            import (`./config/${client}`).then(module => {
                this.configData = module.default;
            });
        },

        keycloakLogin() {
            this.keycloak = keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then((auth) => {

                const roles = [];
                roles['admin'] = '/dashboard';
                roles['manager'] = '/dashboard';
                roles['member'] = '/member/dashboard';
                roles['team'] = '/club/dashboard';
                if (typeof keycloak.resourceAccess.mms !== 'undefined' && ['admin', 'manager', 'member', 'team'].filter(x => keycloak.resourceAccess.mms.roles.includes(x)).length != 0) {
                    localStorage.setItem("token", keycloak.token);
                    localStorage.setItem("refreshToken", keycloak.refreshToken);
                    localStorage.setItem("role", keycloak.resourceAccess.mms.roles);
                    if (window.location.href.split('/mms/')[1] == 'login') {
                        this.$router.push({
                            path: roles[keycloak.resourceAccess.mms.roles[0]],
                        });
                    }
                    this.keycloakLoader = true;
                } else {
                    localStorage.setItem("login-email", typeof keycloak.tokenParsed.email !== 'undefined' ? keycloak.tokenParsed.email : '');
                    localStorage.setItem("login-first_name", typeof keycloak.idTokenParsed.given_name !== 'undefined' ? keycloak.idTokenParsed.given_name : 'test');
                    localStorage.setItem("login-last_name", typeof keycloak.idTokenParsed.family_name !== 'undefined' ? keycloak.idTokenParsed.family_name : 'test');
                    this.$router.push({
                        path: '/memberregistrations',
                    });
                }
            });
        },
        keycloakLogout() {
            /*Keycloak localstorage remove */
            localStorage.clear();
            var return_url = window.location.origin + '/mms';
            var logoutOptions = { redirectUri: return_url }


            keycloak.logout(logoutOptions).then((success) => {
                console.log("--> log: logout success ", success);
            }).catch((error) => {
                console.log("--> log: logout error ", error);
            });
        },
        getUser() {
            let type = localStorage.getItem('role');
            let url = (type == 'admin' || type == 'manager' ? this.basePath + "api/getUser" : this.basePath + "api/getMemberUser");
            if (type == 'club') {
                url = this.basePath + "api/getClubMemberUser";
            }
            axios.get(url, {
                headers: this.userHeader,
            }).then((response) => {
                this.role = response.data.role;
                this.loginUser = response.data.user;
                this.$encryptStorage.setItem("user", JSON.stringify(response.data.user));
                encryptStorage.setItem("user", JSON.stringify(response.data.user));
                encryptStorage.setItem("mms_team", JSON.stringify(response.data.team));
                encryptStorage.setItem("league", JSON.stringify(response.data.league));
                //this.$encryptStorage.setItem("role", JSON.stringify(this.role) );
                this.emitter.emit('userProfileEvent', { 'username': response.data.user.full_name });
            }).catch((error) => {
                //
            });
        },
        getSubscription() {
            var host = window.location.host;
            var subdomain = process.env.VUE_APP_SUBSCRIPTION_NAME ? process.env.VUE_APP_SUBSCRIPTION_NAME : host.split('.')[0];
            axios.post(process.env.VUE_APP_SUBSCRIPTION_URL + "api/subscription-verify", {
                "subdomain": subdomain,
                "module": "MMS"
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                if (response.data.status != 'error') {
                    this.subscriptionChecking(response);
                    if (this.subscriptionStatus == false) {
                        window.location.replace('/mms/unsubscription');
                    }
                    localStorage.setItem("technologyFee", response.data.subscription[0].membership_fee === null ? 0.00 : response.data.subscription[0].membership_fee);
                    localStorage.setItem("processingFee", response.data.subscription[0].processing_fee_value === null || response.data.subscription[0].membership_fee === null ? 0.00 : response.data.subscription[0].processing_fee_value);
                    localStorage.setItem("processingFeePercentage", response.data.subscription[0].processing_fee_percentage === null || response.data.subscription[0].membership_fee === null ? 0.00 : response.data.subscription[0].processing_fee_percentage);

                } else {
                    window.location.replace('/mms/unsubscription');
                }
            }).catch((error) => {
                //
            });
        },
        subscriptionChecking(response) {
            const today = new Date();
            response.data.subscription.some(sub => {
                if (sub.applications.some(a => a.text === 'MMS')) {
                    const startDate = new Date(sub.start_date);
                    const endDate = new Date(sub.end_date);
                    return this.subscriptionStatus = today >= startDate && today <= endDate;
                }
                return this.subscriptionStatus = false;
            });
        },
        getSeason() {
            axios.get(this.basePath + 'api/getSeason', { headers: this.userHeader })
                .then((res) => {
                    this.seasons = res.data.season;
                })
                .catch((error) => {
                    console.log('Error:' + error);
                    return false;
                }).finally(() => {

                });
        },
        getMemberships(type = null) {
            let url = this.basePath + "api/getMemberships";
            if (type) {
                url += "/" + type;
            }
            axios.get(url, { headers: this.userHeader })
                .then((response) => {
                    this.membershipData = response.data.data;
                })
                .catch(error => {
                    console.log("error getting Membership info")
                })
        },
        getMembershipStatus() {
            axios.get(this.basePath + 'api/getMembershipStatus', { headers: this.userHeader })
                .then(response => {
                    this.membershipStatus = response.data.registrationStatus;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        getDiscipline(typ = '') {
            let par = (typ != '') ? '/' + typ : '';
            axios.get(this.basePath + "api/getDisciplines" + par, { headers: this.userHeader })
                .then(response => {
                    this.sportDisciplines = response.data.data;
                    let discipline_codes = Object.keys(this.sportDisciplines);
                    if (discipline_codes.length && this.disciplines.length == 0) {
                        discipline_codes.forEach(code => {
                            this.disciplines.push({
                                id: code,
                                text: this.sportDisciplines[code] == "NA - N/A" ? "N/A" : this.sportDisciplines[code]
                            });
                        });
                    }
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        getTeam() {
            axios.get(this.basePath + 'api/get_teams', { headers: this.userHeader })
                .then((res) => {
                    this.teams = res.data.data;
                })
                .catch((error) => {
                    console.log('Error:' + error);
                    return false;
                }).finally(() => {

                });
        },
        keycloakCheck() {
            this.keycloak = keycloak.init({ onLoad: 'check-sso', checkLoginIframe: false }).then((auth) => {
                this.keycloakStatus = auth;

            });

        },
        dateformat(value) {
            return (value == null) ? 'N/A' : moment(value).format("YYYY-MM-DD HH:mm:ss");
        },

        keycloakSeasonLogout() {
            setInterval(() => {
                keycloak.updateToken(1000).then((refreshed) => {
                    this.pageRender = true;
                    if (refreshed) {
                        this.tokenManage();
                        console.log('Token refreshed', refreshed);
                    } else {
                        console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                    }
                }).catch(() => {
                    this.pageRender = false;
                    console.log('Failed to refresh token');
                    /*  window.location.reload()*/
                    this.keycloakLogout()
                });
            }, 10000)
        },
        getPrivacyPolicy(type) {
            axios.get(this.basePath + 'api/getClientTermsAndPrivacy/' + type, { headers: this.userHeader })
                .then((res) => {
                    if (type === 'privacyPolicy') {
                        this.privacyPolicy = res.data.data;
                    } else {
                        this.termsAndConditions = res.data.data;
                    }
                    this.isLoadingContent = false;
                })
                .catch((error) => {
                    console.log('Error:' + error);
                    return false;
                }).finally(() => {

                });
        },
        emailValidate(value, returnMode = false) {
            let emailValidate = axios.post(this.basePath + "api/emailValidate", { 'email': value }, {
                headers: this.userHeader,
            });
            if (returnMode === true) {
                return emailValidate;
            } else {
                emailValidate.then((response) => {
                    if (response.data.data != 0) {
                        this.emailValidateErr = 'Email Id already exist';
                    }
                }).catch((error) => {
                    //
                });
            }
        },
        emailValidateDuplicate(value) {
            axios.post(this.basePath + "api/emailValidateDuplicate", value, {
                headers: this.userHeader,
            }).then((response) => {
                if (response.data.data != 0) {
                    this.emailValidateErr = 'The associated email has already been registered for the current season';
                }
            }).catch((error) => {
                //
            });
        },
        getClientMenu(type = false) {
            if (!type) {
                type = localStorage.getItem('role');
            }
            axios.get(this.basePath + "api/getClientMenu/" + type, {
                headers: this.userHeader,
            }).then((response) => {
                if (type === 'admin' || type === 'manager') {
                    this.clientMenuAdmin = response.data.data;
                }
                if (type === 'member') {
                    this.clientMenuMember = response.data.data;
                }
                if (type === 'club') {
                    this.clientMenuClub = response.data.data;
                }
            }).catch((error) => {
                //
            });
        },
        checkTheFieldsVisibleOrNot(fields) {
            if (fields == undefined) {
                return true;
            }
            return fields;
        },
        camelizeString(Str) {
            return Str.replace(/_([a-zA-Z])/g, function(m1, m2) {
                return m2.toUpperCase()
            });
        },
        fieldTrigger(trigger) {
            if (trigger == undefined) {
                return true;
            }
            return trigger;
        },
        graduationYear(startValue, endValue) {
            if (startValue !== undefined && endValue !== undefined) {
                const lengthOfYear = parseInt(endValue) - parseInt(startValue);
                const graduationYearArr = Array.from({ length: lengthOfYear }, (value, index) => endValue - index);
                this.graduationYearArr = Object.fromEntries(graduationYearArr.map(key => [key, key]));
            } else {
                const year = new Date().getFullYear();
                const yrs = year + 20;
                const twoDigitYear = yrs.toString().substr(-2);
                const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
                this.graduationYearArr = Array.from({ length: lengthOfYear }, (value, index) => yrs - index);
            }
        },
        jerseyNumber(startValue, endValue) {
            if (startValue !== undefined && endValue !== undefined) {
                const lengthOfList = parseInt(endValue);
                this.jerseyNumberArr = Array.from({ length: lengthOfList }, (value, index) => parseInt(startValue) + index);
            }
        },
        getTeamsList() {
            axios.get(this.basePath + "api/get_teams_division")
                .then((response) => {
                    let teams = response.data.data.team;
                    let isDivision = response.data.data.division;
                    let teamKey = Object.keys(teams);
                    if (teamKey.length) {
                        this.teamList = Object.keys(teams).map((field) => {
                            if (isDivision) {
                                return {
                                    age: field,
                                    value: teams[field]
                                }
                            } else {
                                return {
                                    leagueId: field,
                                    team: teams[field].team
                                }
                            }
                        })
                    }
                    console.log(this.teamList);
                })
                .catch(error => {
                    console.log("error getting teams/clubs list")
                })
        },
        tokenManage() {
            localStorage.setItem("token", keycloak.token);
            localStorage.setItem("refreshToken", keycloak.refreshToken);
        },
        teamValidate(value) {
            axios.post(this.basePath + "api/teamValidate", { 'team': value }, {
                headers: this.userHeader,
            }).then((response) => {
                if (response.data.data != 0) {
                    this.teamValidateErr = 'Team already exist';
                } else {
                    this.teamValidateErr = '';
                }
            }).catch((error) => {
                //
            });
        },
        getCollegeCommittedList() {
            axios.get(this.basePath + "api/college_committed_list")
                .then((response) => {
                    let teams = response.data.data;
                    //this.collegeOptions= response.data.data;
                    let teamKey = Object.keys(teams);
                    if (teamKey.length) {
                        this.collegeOptions = Object.keys(teams).map((field) => {
                            return {
                                id: teams[field].id,
                                text: teams[field].name
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log("error geting clubs list")
                })
        },
        getLeagues() {
            axios.get(this.basePath + "api/league_with_state_code")
                .then(response => {
                    let memberLeague = response.data.data;
                    let memberLeague_codes = Object.values(memberLeague);
                    if (memberLeague_codes.length && this.memberLeague.length == 0) {
                        memberLeague_codes.forEach(code => {
                            this.memberLeague.push({ id: code.id, label: code.name, value: code.state });
                        });
                    }
                })
                .catch(err => {
                    // Manage the state of the application if the request
                    // has failed
                })
        },
        goBack() {
            this.$router.back();
        },
        getExpiresDateStatus() {
            axios.get(this.basePath + 'api/getProperties/ExpiresDateShow')
                .then((res) => {
                    this.expiresDateShowStatus = res.data.properties == null ? true : res.data.properties;
                })
                .catch((error) => {

                }).finally(() => {});
        },
        getClientLogo() {
            axios.get(this.basePath + 'api/getProperties/client_logo')
                .then((res) => {
                        this.mmsClientLogo = res.data.properties;
                })
                .catch((error) => {

                }).finally(() => {
            });
        },

        loginPageInitiated() {
            axios.get(this.basePath + "api/login-page-cnt/login_page", {
                headers: this.header,
            }).then(function (response) {
                if(response.data?.properties?.realm) {
                    this.customRealm = response.data.properties.realm;
                    localStorage.setItem("customRealm",this.customRealm);
                    this.loginPageNavigation = response.data.properties.login_page_navigation;
                    if(this.loginPageNavigation){
                        this.$router.push({ name: 'login' });
                    }
                }
            }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        },
        isSpecifiedRealm() {
            if(this.customRealm) {
                window.location.href = window.location.origin + "/"+ this.customRealm;
                return true;
            }
            return false;
        },
        visibilityOfDatatableAdditionalColumns(table, tableConfig) {
            if (tableConfig?.tableColumns) {
                Object.entries(tableConfig.tableColumns).forEach(([index, value]) => {
                    let tableColumnIndex = table.settings()[0].aoColumns.findIndex(function (column) {
                        return column.name === index;
                    });
                    if (tableColumnIndex >= 0) {
                        table.column(tableColumnIndex).visible(value);
                    }
                });
            }
        },
        validateSign(required,rules) {
            if(rules!='' && required){
              if (typeof rules !== "string") {
                rules = String(rules);
              }
              let requiredExists = rules.includes("required");         
              if(required && requiredExists) {
                return true
              }
              else {
                return false
              }  
            }
        },
        multiFieldTruncated(teams) {
            if (teams) {
                let data = teams.split(',');
                if (data.length > 1) {
                    return '<span class="drkblubg p-2 rolbtncomn rounded-pill text-white">' + this.truncateString(data[0], 25) + '</span>' +
                        (data.length > 1 ? '<span class="count border p-2 color-30 rounded-pill ml-2 rolbtncomn" data-toggle="tooltip" data-placement="top" data-html="true" title="'
                            + data.join(', ') + '">+' + (data.length - 1) + '</span>' : '');
                }
            }
            return teams;
        },
    },
    watch: {
        '$globalPageLoader.value': {
            handler: function() {
                this.updatePageLoading()
            },
            deep: true
        }
    },
    computed: {
        loginUserRole() {
            let role = localStorage.getItem("role");
            if (role != undefined && role != null) {
                return role;
            }
            return '';
        },
        currentDate() {
            return ref(new Date());
        },
        loginUserName() {
            let user = encryptStorage.getItem("user");
            if (user != null) {
                return user.full_name;
            }
            return '';
        },
        loginUserDetails() {
            let user = encryptStorage.getItem('user');
            if (user != null) {
                return user;
            }
            return '';
        },
        loginUserLeague() {
            let user = encryptStorage.getItem('league');
            if (user != null) {
                return user;
            }
            return '';
        },
        loginUserTeam() {
            let user = encryptStorage.getItem('mms_team');
            if (user != null) {
                return user;
            }
            return '';
        },
        loginUserProfilePic() {
            let user = encryptStorage.getItem("user");
            if (user != null && user.profile_pic != '' && user.profile_pic != undefined) {
                return this.basePath + "/uploads/profile/" + user.profile_pic;
            } else {
                return '';
            }
        },
        getLoggedinUserAccountId() {
            let user = encryptStorage.getItem("user");
            if (user != null) {
                return user.user_account_id;
            }
            return '';
        },
        years() {
            return new Date().getFullYear() + 1;
        },
        memberStatus() {
            let user = encryptStorage.getItem("user");
            if (user != null) {
                return user.status_text;
            }
            return '';
        }

    }
}


$(document).on('click', '.faqlisting h5', function(e) {
    e.preventDefault();
    var $this = $(this);
    var $parentLi = $this.closest('div');

    if ($this.next().hasClass('show')) {
        $this.next().removeClass('show');
        $this.next().slideUp(350);
        $this.removeClass('active');
    } else {
        $this.parent().find('h5').removeClass('active');
        $this.parent().find('.inner').removeClass('show');
        $this.parent().find('.inner').slideUp(350);

        $parentLi.siblings().find('.inner').removeClass('show');
        $parentLi.siblings().find('.inner').slideUp(350);
        $this.next().addClass('show').slideDown(350);
        $this.parent().siblings().find('.show').removeClass('show');
        $this.parent().siblings().find('.inner').slideUp(350);
        $this.addClass('active');
    }
});
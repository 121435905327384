<template>
    <div class="delte-img text-center mt-2">
       <img src="../assets/img/logo/delete_account.png" class="img-fluid" alt="logo">                       
    </div>
</template>

<script>

  export default {
    name: 'deleteaccountimg',
  };
</script>

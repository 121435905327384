import "bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"
import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import IsportzDashboard from "./components/plugins/isportz-dashboard"
import Vue3Transitions from 'vue3-transitions'
import SidebarPlugin from "./components/SidebarPlugin";
import GlobalVars from './globalVars';
import VeeValidatePlugin from './plugin/validation';
import mitt from 'mitt'
import VueSweetalert2 from 'vue-sweetalert2';
import VueCropper from 'vue-cropperjs';
import VueCryptojs from 'vue-cryptojs';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import 'cropperjs/dist/cropper.css';


import 'vue3-tel-input/dist/vue3-tel-input.css'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import './assets/css/style.css'
import './assets/css/responsive.css'
import http from './lib/axios'
import store from './store/index'
import VueTheMask from 'vue-the-mask'
import { encryptStorage } from "./lib/encrypted-storage"
import CKEditor from '@ckeditor/ckeditor5-vue';
import Vue3TagsInput from 'vue3-tags-input';

import './assets/css/newStyle.css';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import VueCookies from 'vue-cookies';

import {
    // Directives
    VTooltip,
    VClosePopper,
    // Components
    Dropdown,
    Tooltip,
    Menu
} from 'floating-vue'

const emitter = mitt();
import 'floating-vue/dist/style.css';
import AddToCalendarButton from 'add-to-calendar-button-vue';

const app = createApp(App)
app.config.globalProperties.$http = http
app.config.globalProperties.$encryptStorage = encryptStorage
app.use(store)
app.use(VueCookies)
app.use(Toast);
app.use(VeeValidatePlugin);
app.use(IsportzDashboard)
app.use(SidebarPlugin)
app.mixin(GlobalVars)
app.use(Vue3Transitions)
app.use(router)
app.config.globalProperties.$globalPageLoader = ref(false);
app.config.globalProperties.emitter = emitter
app.use(VueSweetalert2);
app.use(VueTheMask);
app.use(VueCryptojs);
app.use(CKEditor);
app.use(Vue3TagsInput);
app.directive('tooltip', VTooltip)
app.directive('close-popper', VClosePopper)

app.component('VDropdown', Dropdown)
app.component('VTooltip', Tooltip)
app.component('VMenu', Menu)
app.component('VueCropper', VueCropper)
app.component('AddToCalendarButton', AddToCalendarButton)
app.use(PerfectScrollbar)

$.fn.dataTable.ext.errMode = 'none';
app.mount('#app')
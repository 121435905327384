import axios from 'axios'
import { encryptStorage } from "../encrypted-storage"

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    'ClientId':  localStorage.getItem('ClientId'),
    team : encryptStorage.getItem('team') ? encryptStorage.getItem('team').id : '',
    userType: encryptStorage.getItem('user_type')
  }
})


axiosIns.interceptors.response.use( (response) => {
  return response; 
}, function (err) {
  return Promise.reject(err);
});

export default axiosIns

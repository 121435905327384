import http from '../../lib/axios'

let client = window.location.hostname.split('.')[0];
let config = '';
if (client) {
    config = require(`../../config/${client}.json`);
}

export default {
    state: {
        myTeams: [],
        globalConfig: []
    },
    actions: {
        async myTeams({ commit }) {
            await http.get('api/my-teams')
                .then(res => {
                    commit('SET_MY_TEAMS', res.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async globalConfig({ commit }) {
            commit('SET_GET_CONFIG', config)


        }
    },

    getters: {
        getMyTeams: state => {
            return state.myTeams
        },
        getConfig: state => {
            return config
        }
    },

    mutations: {
        SET_MY_TEAMS(state, data) {
            state.myTeams = data
        },
        SET_GET_CONFIG(state, data) {
            state.globalConfig = data
        }
    }

}
import http from '../../lib/axios'
import axios from "axios";
import {encryptStorage} from "@/lib/encrypted-storage";

export default {
    state: {
        userRoles: [],
        userPermissions: []
    },

    actions: {
        async getUserRoles({ commit }) {
            commit('SET_USER_ROLES', localStorage.getItem("role"))
        },

        getUserPermissions({ commit }) {
            let headers = {
                "Accept": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'ClientId': localStorage.getItem('ClientId'),
                'team': encryptStorage.getItem('team') ? encryptStorage.getItem('team').id : '',
                'userType': encryptStorage.getItem('user_type')
            };
            axios.get(window.location.origin + '/mmsapp/api/user/permissions/' + localStorage.getItem("role"), { headers: headers })
                .then((response) => {
                    commit('SET_USER_PERMISSIONS', response.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }

    },

    getters: {
        fetchUserRoles: state => {
            return state.userRoles
        },
        fetchUserPermissions: state => {
            return state.userPermissions
        }
    },

    mutations: {
        SET_USER_ROLES(state, data) {
            state.userRoles = data
        },
        SET_USER_PERMISSIONS(state, data) {
            state.userPermissions = data
        }
    }

}
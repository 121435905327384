<template>
  <nav
    class="navbar"
    :class="[
      { 'navbar-expand-md': expand },
      { 'navbar-transparent': transparent },
      { [`bg-${type}`]: type },
    ]"
  >

    <div :class="containerClasses">
      <!-- <router-link class="navbar-brand" to="/">       
        <img src="../assets/img/svg/menuicon.svg" class="navbar-brand-img" alt="..." />
      </router-link>    -->
      <button @click.native="toggleSidebar" class="topnavbartoggle"> <img src="../assets/img/svg/menuicon.svg" class="navbar-brand-img" alt="..." /></button>       
      <navbar-toggle-button
        v-if="showToggleButton"
        :toggled="toggled"
        :target="contentId"
        @click.stop="toggled = !toggled"
      >
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>

      <div
        class="collapse-nav d-flex align-items-center justify-content-center"
        :class="{ show: toggled }"
        :id="contentId"
        v-click-outside="closeMenu"      >
        <slot :close-menu="closeMenu"></slot>
      </div>
    </div>
  </nav>
  
</template>
<script>
import NavbarToggleButton from "./NavbarToggleButton";
import { collapsed, toggleSidebar } from '@/components/SidebarPlugin/index'

export default {
  name: "base-nav",
  components: {
    NavbarToggleButton,
  },
  props: {
    type: {
      type: String,
      default: "",
      description: "Navbar type (e.g default, primary etc)",
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar",
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
        "Explicit id for the menu. By default it's a generated random number",
    },
    containerClasses: {
      type: [String, Object, Array],
      default: "container-fluid",
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent",
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class",
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toggled: false,
    };
  },
  methods: {
    closeMenu() {
      this.toggled = false;
    },
  },
  setup() {
    return { collapsed, toggleSidebar }
  }
};
</script>
<style>
button.topnavbartoggle {
    background: transparent;
    border: none;
}
</style>

<template>
  <router-view />
</template>

<script>
import Spinner from "./components/Spinner"

export default {
   components: {
     Spinner
  },
  setup() {

  },
  async beforeCreate(){

    /* Prod site favicon change*/
    let host = window.location.host;
    var favimage = host.split('.')[0] + '_favicon.png';
    try {
      require(`../public/img/logo/${favimage}`);
      let favicon = window.location.origin + "/mms/public/img/logo/" + host.split(".")[0] + "_favicon.png";
      const link = document.getElementById("prod_fav_icon") || document.createElement("link");
      link.rel = "icon";
      link.href = favicon;
      document.getElementsByTagName("head")[0].appendChild(link);      
    }catch{
      let favicon = window.location.origin + "/mms/public/img/logo/isportz_favicon.png";
      const link = document.getElementById("prod_fav_icon") ||   document.createElement("link");
      link.rel = "icon";
      link.href = favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    
   if(localStorage.getItem("token")){
      await this.$store.dispatch('getUserPermissions')
      await this.$store.dispatch('getUserRoles')
    }
  }
}
</script>



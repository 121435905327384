import { createStore } from 'vuex'
import rolePermission from './api/rolesPermissions'
import clubUser from './api/clubuser'

// Create a new store instance.
const store = createStore({
  modules: {
    rolePermission,
    clubUser
  },
  strict: process.env.DEV
})

export default store
<template>
  <div class="group-radio" :class="circle ? 'radio-circe' : ''">
    <Field v-slot="{ field, errorMessage }" v-model="model" :name="name" >
      <div
          class="donation-check d-flex mr-3"
          :class="[inlineClass, { disabled: disabled }]"
          v-for="(val, key) in options"
          :key="key"
      >
        <input
            :id="val+cbId"
            v-bind="field"
            type="radio"
            :value="key"
            :checked="model == key"
        />

        <label :for="val+cbId" class="">
          <slot>{{ val}}</slot>
        </label>
      </div>
      <div  class="invalid-feedback" style="display: block;">
        {{ errorMessage  }}
      </div>
    </Field>
  </div>
</template>
<script>
import { randomString } from "./stringUtils";
export default {
  name: "base-radio",
  props: {
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: ''
    },
    disabled: {
      type: Boolean,
      description: "Whether radio is disabled",
    },
    circle: {
      type: Boolean,
      description: "design circe",
      default: false
    },
    value: {
      type: [String, Boolean],
      description: "Radio value",
    },
    modelValue: {
      type: [String, Boolean],
      description: "Radio value",
    },
    checkedVal: {
      type: [String, Boolean],
      description: "Radio value",
    },
    inline: {
      type: Boolean,
      description: "Whether radio is inline",
    },
    label: {
      type: String,
      description: ""
    },
    options: {
      default: []
    },
  },
  data() {
    return {
      cbId: "",
    };
  },
  methods: {
    radioGroups(value){
      this.$emit('update:model', value);
    }
  },
  mounted() {
    this.cbId = randomString();
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:checked", value);
      },
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return "";
    }

  }
};
</script>

<template>
<div class="row ml-0 mr-0 mt15">
    <div class="col-md-12">
    <div class="card">
            <div class="text-center py-5 100vh">
              <h3 class="text-center mt-5 font-weight-500 text-lg color-30">{{ contentMessage }}</h3>
              <div>
                <img src="../assets/img/General/accessdenied.png" class="img-fluid" alt="permissiondenied">
              </div>
              <a :href="this.loginUserRole == 'admin' ? 'dashboard' : this.loginUserRole +'/dashboard'" class="btn btn-primary btn-lg btn-theme mt-3 mb-2 px-5 rounded-sm">Back To Dashboard</a>
    </div>

    </div>
    </div>

</div>


</template>


<script>
export default {
  name:'PermissionDenied',
  computed: {
    contentMessage(){
      if(this.$route.name == 'Payments'){
        return 'You do not have any products in cart to purchase';
      }else{
        return 'Access Denied';
      }
    }
  }
}
</script>
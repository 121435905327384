<template>
<div class="loader">
    <ContentLoader viewBox="0 0 1100 2300" primaryColor="#f3f3f3" secondaryColor="#D9D4D4">
        <rect x="0" y="100" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="300" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="500" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="700" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="900" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="1100" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="1300" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="1500" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="1700" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="1900" rx="3" ry="3" width="1100" height="160" />
        <rect x="0" y="2100" rx="3" ry="3" width="1100" height="160" />
    </ContentLoader>
</div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
export default {
    name: 'ListLoader',
     components: {
        ContentLoader,        
		},
}
</script>

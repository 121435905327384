<template>
    <footer class="mainfooter">
        <div class="container-fluid footer-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <p class="m-0 text-right">Powered by <a href="https://isportz.co" target="_blank" class="powered_by ml-1"><img
                        src="@/assets/img/isportz-logo.svg" alt="iSportz" /></a></p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterLayout'
};
</script>
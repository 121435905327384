<template>
  <div
    class="custom-control custom-checkbox"
    :class="{ disabled: disabled, 'form-check-inline': inline }"
  >
   <Field :name="name" :rules="rules" v-slot="{ field, errors }">
    <input
      v-bind="field"
      :id="cbId"
      class="custom-control-input"
      type="checkbox"
      :disabled="disabled"
      v-model="model"
      :checked="checked"
      @input="(event) => $emit('update:checked', event.target.checked)"
    />

    
    <label :for="cbId" class="custom-control-label color-7a">
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
          <slot name="errors">
      <div  v-for="(error , index ) in errors" :key="index" class="invalid-feedback" style="display: block;">
        {{ error }}
      </div>
    </slot>
    </Field>
  </div>
</template>
<script>
import { randomString } from "./stringUtils";

export default {
  name: "base-checkbox",
  model: {
    prop: "checked",
  },
  props: {
    checked: {
      type: [Array, Boolean],
      description: "Whether checkbox is checked",
    },
    disabled: {
      type: Boolean,
      description: "Whether checkbox is disabled",
    },
    inline: {
      type: Boolean,
      description: "Whether checkbox is inline",
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: ''
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
  },
  data() {
    return {
      cbId: "",
      touched: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit("input", check);
      },
    },
  },
  mounted() {
    this.cbId = randomString();
  },
};
</script>

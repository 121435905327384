<template>
  <div class="col-md-12 filupload">
    <div class="file-upload-section" v-if="file.success.enabled == false">
      <div class="sample-template text-right" v-if="downloadSampleTemplate == true">
          <button @click="downloadSample()" type="button" class="btn downloadex btn-sm mr-0">
            <img class="img-fluid" style="width:25px;" src="../assets/img/svg/file.svg" alt="fileupload"/>Download Sample Template
          </button>
      </div>
        <VeeValidateForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div" ref="formValidator">
          <form role="form" ref="form" @submit="handleSubmit($event, uploadCSV)">
            <div class="upload-btn-wrapper text-right">
              <button class="btn"><img class="text-center img-fluid" src="../assets/img/svg/csvupload.svg" alt="fileupload"/></button>
              <Field v-slot="{ field, errorMessage }" v-model="form.file" v-bind="field"  name="file" >
                <input ref="file" type="file"  @change="changeFile"/>
                <div  class="invalid-feedback" style="display: block;">
                    {{ errorMessage  }}
                </div>
              </Field>
              <span class="d-block text-danger font-14 text-center pr-4">Supported Formats : {{ fileType.toUpperCase() }} Files Only</span>
            </div>
            <div class="border-dotted pb-3 p-2" v-if="file.name != ''">
              <h6 class="font-16 color-333 mb-4 mt-4">Selected File</h6>  
              <div class="d-flex">
                  <div class="upload-icon">
                    <img class="imgwidth mr-3" style="width:35px;" src="../assets/img/svg/file.svg" alt="upload_icon" />
                  </div> 
                  <div class="upload-docname">
                    <span class="d-block color-333 text-sm">{{ this.file.name }}</span>
                    <span class="d-block color-333 text-xs">{{ this.file.date }}</span>
                  </div>
                  <div class="upload-progress ml-auto">
                   
                    <div v-if="file.percentage.enabled" role="progressbar" :aria-valuenow="file.percentage.value" aria-valuemin="0" aria-valuemax="100" :style="'--value:'+file.percentage.value"></div>
                  </div>
              </div>
            </div>
            <div class="cs mt-2 text-right" v-if="file.name != ''">
              <button @click="removeFile()" type="button" v-if="file.btn.disabled == false" class="btn btn-outline-socondary">Remove</button>
            <base-button type="primary" native-type="submit" class="btn btn-secondary" :disabled="file.btn.disabled">{{ file.btn.text }}</base-button>
            </div>            
          </form>
        </VeeValidateForm>
        <div class="field-errors">
          <ul v-for="(error, index) in file.errors" :key="index">
              <li class="err-list">{{ error }}</li>
          </ul>
      </div>
    </div>
    <div class="file-upload-success-report pt-5 pb-5 p-2" v-else>
      <h4> {{ reportResultText?.title ? reportResultText.title : 'Import Report'  }}</h4>
        <table class="table table-hover table-bordered">
            <tbody>
              <tr v-if="'total' in file.success.records">
                <td>{{ reportResultText?.total ? reportResultText.total : 'Total Records'  }}</td> <td>{{ file.success.records.total }}</td>
              </tr>
              <tr v-if="'success' in file.success.records">
                <td>{{ reportResultText?.success ? reportResultText.success : 'Record Inserted'  }}</td> <td>{{ file.success.records.success }}</td>
              </tr>
              <tr v-if="'exists' in file.success.records">
                <td>{{ reportResultText?.exists ? reportResultText.exists : 'Record Exists'  }}</td> <td>{{ file.success.records.exists }}</td>
              </tr>
              <tr v-if="'skipped' in file.success.records">
                <td>{{ reportResultText?.skipped ? reportResultText.skipped : 'Record Skipped'  }}</td> <td>{{ file.success.records.skipped }}</td>
              </tr>
            </tbody>
        </table>
        <div class="mt-2 mb-4 pt-3">
          <base-button type="primary" native-type="button" class="btn btn-outline-socondary" @click="removeFile()">Back</base-button>
          <a v-if="downloadImportReport == true && file.success.records.report != '' && file.success.records.report != null" href="javascript:void(0);" class="btn btn-secondary" @click="downloadReport()">Download Report</a>
        </div>
    </div>
  </div>
</template>
<script>
import { randomString } from "./stringUtils";
import axios from 'axios';
import moment from 'moment';
import { boolean } from 'yup/lib/locale';
import {encryptStorage} from "@/lib/encrypted-storage";
export default {
  name: "upload-file",
  props: {
    payloadFormData: {
      type: Object,
      description: 'Form Data',
      default() {
        return {};
      }
    },
    name: {
      type: String,
      description: 'File Name',
      default: 'user_import'
    },
    downloadSampleTemplate : {
      type: Boolean,
      description: 'Download the sample import template',
      default: false
    },
    downloadImportReport : {
      type: Boolean,
      description: 'After import generate the detailed report',
      default: false
    },
    requestUrl : {
      type: String,
      description: 'Axios file path',
      default: 'import-csv/userdata'
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: 'required'
    },
    fileType : {
      type: [String],
      description: 'File accept types',
      default: 'csv'
    },
    reportResultText: {
        type: Object,
        default() {
            return {
              'title': 'Import Report',
              'total': 'Total Records',
              'success': 'Record Inserted',
              'exists': 'Record Exists',
              'skipped': 'Record Skipped',
            };
        }
    },
  },
  data() {
    return {
      schema : {
        file: this.rules
      },
      file : {
        name : '',
        date : '',
        btn : {
          disabled : false,
          text : 'Import'
        },
        percentage : {
          enabled : false,
          value : 0
        },
        success: {
          enabled : false,
          records : {
            total : 0,
            exists : 0,
            success : 0,
            skipped : 0,
            report : ''
          }
        },
        errors : []
      },

      form : {
        file: ''
      }
    };
  },
  methods : {
    currentDateTime(){
      this.file.date =  moment().format('MM-DD-YYYY , h:mm:ss a');
    },
    changeFile(e){
      this.file.name = e.target.files[0].name;
      this.form.file = e.target.files[0];
      this.currentDateTime();
    },
    uploadCSV(){
     

      let formData = new FormData();
      formData.append('file', this.form.file);
      if (this.payloadFormData) {
        Object.entries(this.payloadFormData).forEach(function([key, value]) {
          formData.append(key, value);
        });
      }
      this.file.errors = [];
			const config = {
				onUploadProgress: function (progressEvent) {
          // this.file.percentage.enabled = true;
				this.file.percentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				}.bind(this),
          headers: {
            'Authorization':'Bearer '+localStorage.getItem('token'),
            'Content-Type': 'multipart/form-data',
          }
        };
      this.uploadButtonContrroll(true , 'Importing Inprocess..');
      axios.post(this.basePath+"api/"+this.requestUrl, formData, config)
      .then(response => {
        const resp = response.data.data;
        this.file.success.enabled = true;
        this.file.success.records = resp;
        this.toast.success(response.data.message, {
            timeout: 3000
        });
      }).catch(err => {
        let errors = err.response.data.errors;
        
        this.toast.error('Records Import Failed', {
            timeout: 3000
        });

        if(Object.keys(errors).length){
          this.file.errors = Object.values(errors).flat();
        }
      }).finally(()=> {
          this.uploadButtonContrroll(false , 'Import');
      });
    },
    downloadSample(){
      let file_name = this.getFilename;
      let url = this.basePath + "api/import-csv/download-sample?sample="+file_name;
      axios.get(url, {headers:this.userHeader, responseType: 'arraybuffer'})
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'templete_'+file_name);
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log(err);
      })
    },
    downloadReport(){
      let file_name = this.file.success.records.report;
      let url = this.basePath + "api/import-csv/download-report?filepath="+file_name;
      axios.get(url, {headers:this.userHeader, responseType: 'arraybuffer'})
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.name+'_report.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log(err);
      })
    },
    removeFile(){
      this.file.name = '';
      if(this.$refs.file != null) this.$refs.file.value = '';
      this.form.file = '';
      this.file.success.enabled = false;
      this.file.errors = [];
    },
    uploadButtonContrroll(isDisabled , text){
      this.file.btn.disabled = isDisabled;
      this.file.btn.text = text;
    }
  },
  computed: {
    getFilename(){
      return this.name+'.'+this.fileType;
    }
  },
  mounted() {
    
  },
};
</script>
<style>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
@keyframes growProgressBar {
  0%, 33% { --pgPercentage: 0; }
  100% { --pgPercentage: var(--value); }
}

@property --pgPercentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}
.field-errors ul {
    padding: 0;
}

.field-errors ul li {
    list-style: none;
    font-size: 12px;
    color: #fb6340;
}

.field-errors ul {
    margin-bottom: 0;
}

div[role="progressbar"] {
  --size: 4rem;
  --fg: #f7b808;
  --bg: #ddd;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: 
    radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0)
    ;
    font-size: 13px;
    color: #304C93;
}

div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) '%';
}
</style>
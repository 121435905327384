<template>
  <header class="mainheader">
    <a href="javascript:void(0)" class="nav-toggler deskToggler" v-bind:class="header ? 'menu_opened' : 'menu_closed'"  v-on:click="deskToggler">
      <div class="nav-toggle">
        <div class="icon-bar"></div>
        <div class="icon-bar"></div>
        <div class="icon-bar"></div>
      </div>
    </a>

    <a href="javascript:void(0)" class="nav-toggler mobToggler" v-bind:class="header ? 'menu_opened' : 'menu_closed'" v-on:click="mobToggler">
      <div class="nav-toggle">
        <div class="icon-bar"></div>
        <div class="icon-bar"></div>
        <div class="icon-bar"></div>
      </div>
    </a>

    <div class="header-main">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 d-flex flex-wrap align-content-center">

            <div class="app-logo flex-grow-1 text-left">            
              <div class="setlognewwrap">     
               <a href="javascript:void(0);">
                  <img :src="this.mmsClientLogo" @error="logoImageError" :alt="this.mmsClientLogo" />
               </a>
              </div>          
            </div>
            <div class="ml-auto d-flex flex-wrap align-content-center header_nodify_sec">
              <div class="dropdown user_profile_nav">
                <button class="btn shadow-none dropdown-toggle d-flex flex-wrap align-items-center  p-0" type="button"
                  id="userProfileNav" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="header_icon">
                    <img src="../assets/icons/user.svg" alt="profile image" /></span>
                  <span class="profile_name text-capitalize mr-2 ml-2"> {{ this.loggedUser['userName'] ?
                    truncateString(this.loggedUser['userName'], 15) : '' }}</span>
                </button>
                <div class="dropdown-menu pull-right" aria-labelledby="userProfileNav"><span
                    class="dropdown-menu-arrow"></span>
                  <div class="dropdown-header">Welcome!</div>
                   <a class="dropdown-item" disabled href="javascript:void(0)" v-if="adminAccess"><i class="profile_icons profile_icon"></i> My
                    Profile</a>
                    <a class="dropdown-item"  href="/mms/member-profile" v-else><i class="profile_icons profile_icon"></i> My
                    Profile</a> 

                    <a class="dropdown-item" disabled href="javascript:void(0)" v-if="adminAccess"><i class="profile_icons setting2_icon"></i>
                    Settings</a>
                    <a class="dropdown-item"  href="/mms/member/preference" v-else><i class="profile_icons setting2_icon"></i>
                    Settings</a>

                    <a class="dropdown-item"  href="/subscriptions" v-if="adminAccess"><i class="profile_icons subscription_icon"></i>
                    Subscriptions</a>
                    <a class="dropdown-item"  href="/mms/help"  v-if="adminAccess"><i class="profile_icons help_icon"></i>
                      Help Center</a>
                  <a class="dropdown-item" href="javascript:void(0)" @click="forceLogout"><i
                      class="profile_icons logout_icon"></i> Logout</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>


<script>
import { logout, decodeKeycloakToken, getRoles } from "@/keycloakAuth";

export default {
  name: 'HeaderLayout',
  data: function () {
    return {
      header: false,
      mobHeader: false,
      loggedUser: [],
      adminAccess :false,
      prodlogo:'',
      logofound:true
    }
  },
  computed: {
    activeProd() {
      let siteOrigin = new URL(window.location.href).pathname.match(/[^\/]+/g);
      this.activeNav = siteOrigin[0];
      return siteOrigin[0];
    },
    userDisplayName() {
      return this.$store.getters.getUsername;
    },
  },
  created() {
    /* sidebar to header callback */
    this.emitter.on('mobTogglerClose', (evt) => {
      this.mobHeader = evt;
      this.emitter.emit('showmenu-mob', this.mobHeader)
    })

    /* Desktop to header callback, submenu open always */
    this.emitter.on('deskTogglerOpen', (evt) => {
      this.header = true;
      this.emitter.emit('showmenu-event', true);
      if (this.header) {
        document.body.classList.add("sidebar-full-view");
        document.body.classList.remove("sidebar-half-view");
      } else {
        document.body.classList.remove("sidebar-full-view");
        document.body.classList.add("sidebar-half-view");
      }
    });

    /* Logged user profile */
    let userData = decodeKeycloakToken();
    if (userData) {
      this.loggedUser['userName'] = userData.name;
    }

  },
  methods: {    
    forceLogout() {
      logout();
    },
    setUserType(team, userType) {
      this.$encryptStorage.setItem('team', team)
      this.$encryptStorage.setItem('user_type', userType)
      window.location.href = '/' + process.env.VUE_APP_PRODUCT_SLUG + '/dashboard';
    },
    deskToggler() {
      /* header to sidebar callback, desktop toggler click */
      this.header = !this.header;
      this.emitter.emit('showmenu-event', this.header);
      if (this.header) {
        document.body.classList.add("sidebar-full-view");
        document.body.classList.remove("sidebar-half-view");
      } else {
        document.body.classList.remove("sidebar-full-view");
        document.body.classList.add("sidebar-half-view");
      }
    },
    mobToggler() {
      /* header to sidebar callback, mobile toggler click */
      this.mobHeader = !this.mobHeader;
      this.emitter.emit('showmenu-mob', this.mobHeader)
    },
    async prodlogomtd(){
      let host = window.location.host;
      let imageName = host.split('.')[0] + '_logo.png';
      this.logofound=true;
      this.prodlogo = require(`@/assets/img/logo/${imageName}`);
    },
    logoImageError(){
      console.log('test logo error')
      /* if logo not found in directory move else conditions in img tag. */
      this.logofound=false;
    },
    isSassAdmin() {
      let keycloakRoles = getRoles('subscriptions');
        if( keycloakRoles && keycloakRoles.includes('admin') ) {
          window.location.href = window.location.origin + "/subscriptions/subscription";
          return false;
        }
        return true;
    },
    isSpecifiedRealm() {
      if(localStorage.getItem("customRealm")) {
        window.location.href = window.location.origin + "/"+ localStorage.getItem("customRealm");
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.prodlogomtd();
    let api_role_arr = getRoles('mms');
    if (api_role_arr != undefined && api_role_arr.indexOf('admin') !== -1) {
      this.adminAccess = true;
    }
    this.deskToggler()
    this.getClientLogo();
  },
  beforeMount() {
    this.isSpecifiedRealm();
    this.isSassAdmin();
  }
};
</script>
<template>
  <nav
   :class="{ 'mini-sidebar': collapsed }"
  :style="{ width: sidebarWidth }"
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white sidebarcss"
    id="sidenav-main"
  >
    <div class="container-fluid">      
      <!--Toggler-->   
      <div class="sidebarlogo">
      <router-link v-if="collapsed" class="navbar-brand hidemobile" to="/">
        <img src="../../assets/img/svg/i-logo.png" class="navbar-brand-img" alt="..." />
      </router-link>
      <router-link v-else class="navbar-brand hidemobile" to="/">
        <img :src="image" class="navbar-brand-img" alt="isportz"/>
      </router-link>
      <img src="../../assets/img/logo/logo.svg" class="navbar-brand-img mobilelogo" alt="..." />
      </div>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header ">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";

import { collapsed, toggleSidebar, sidebarWidth } from './index.js'

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: "",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  data(){
   return {
     image : 'isportz_dashboard_img.svg'
   }
  },
   setup() {
    return { collapsed, toggleSidebar, sidebarWidth }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  watch:{
		$route (to, from){
			if(window.innerWidth < 768) {				
			 this.collapsed = false;
			}
		}
  },
  mounted() {
    var host = window.location.host;
    var image = host.split('.')[0] + '_dashboard_img.svg';
    try {
      this.image =  require(`../../assets/img/logo/${image}`)
    }catch{
      this.image = require(`../../assets/img/logo/isportz_dashboard_img.svg`)
    }
  }
};
</script>

<style>
.mini-sidebar{
 transition: 0.3s ease;
}

.mini-sidebar ul.sidebar-nav li.nav-item a p,
.mini-sidebar ul.navbar-nav li a {
    font-size: 0;
}
.navbar-vertical{
transition: 0.2s linear;
}
/* .navbar-vertical ul.navbar-nav li a{
transition: 0.2s linear;
} */
.mini-sidebar ul.navbar-nav li a span {
    font-size: 0;
}
.mini-sidebar ul.navbar-nav li a {
    padding-left: 18px !important;
     transition: 0.3s ease;
}
.mini-sidebar ul.navbar-nav li a {
    height: 42.9px;
    transition: 0.2s linear;
}
.mini-sidebar.navbar-vertical.navbar-expand-md.fixed-left + .main-content {
    margin-left: 79px;
    transition: 0.3s ease;
}
.navbar-vertical.navbar-expand-md.fixed-left + .main-content {
    transition: 0.3s ease;
    transition: 0.2s linear;
}
</style>
<template>
  <div class="site-logo" style="margin-top:50px;">
    <ul class="d-flex suportlink align-items-center">
      <li v-if="this.mmsClientLogo">
        <a href="#" class="logo" :class="ifAlliance ? 'afplogo': ''" ><img :src="this.mmsClientLogo" @error="handleImageError" alt="logo" class="mb-0"></a>
      </li>
      <li class="ml-auto">
         <a class="text-white supportbg" :href="supportLink" :target="supportLinktarget"><img class="mr-2" src="../assets/icons/support.svg" />Support</a>
      </li>
    </ul>
  </div>
</template>

<script>
import fallbackImage from '@/assets/img/logo/isportz_dashboard_img.svg';

export default {
  name: 'logo',
  data(){
    return {
      logo_path:'',     
        ifAlliance: false,
        supportLink:'',
        supportLinktarget:''
    }
  },
  methods: {
    handleImageError(event) {
      // Set the src of the image to the fallback URL
      event.target.src = fallbackImage;
      }

    },
  mounted() {
    var host = window.location.host;       
    if(host.includes("pre-afp") || host.includes("afp") || host.includes("devafp") ){
      this.supportLink = 'help-and-support'; 
      this.supportLinktarget ='_self';   
     }  
     else{
      this.supportLink='https://support.isportz.co/portal'
      this.supportLinktarget ='_blank'; 
     }
    this.getClientLogo();
    
  }
};
</script>
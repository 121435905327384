import Badge from "../Badge.vue";
import BaseAlert from "../BaseAlert.vue";
import BaseButton from "../BaseButton.vue";
import BaseCheckbox from "../BaseCheckbox.vue";
import BaseDropdown from "../BaseDropdown.vue";
import BaseHeader from "../BaseHeader.vue";
import BaseInput from "../BaseInput.vue";
import BaseNav from "../BaseNav.vue";
import BasePagination from "../BasePagination.vue";
import BaseProgress from "../BaseProgress.vue";
import BaseSwitch from "../BaseSwitch.vue";
import BaseRadio from "../BaseRadio.vue";
import BaseRadioGroup from "../BaseRadioGroup.vue";
import CustomRadioGroup from "../CustomRadioGroup.vue";
import BaseTable from "../BaseTable.vue";
import Select2 from 'vue3-select2-component';
// import Modal from "../Modal.vue";
import StatsCard from "../StatsCard.vue";
import UploadFile from "../FileUpload.vue";
import UploadImage from "../ImageUpload.vue";
// import TabPane from "../Tabs/TabPane.vue";
// import Tabs from "../Tabs/Tabs.vue";
import { Form, Field , ErrorMessage } from 'vee-validate';
import Datepicker  from 'vue3-datepicker'
import { VueTelInput } from 'vue3-tel-input'
import Multiselect from 'vue-multiselect'
import myUpload from 'vue-image-crop-upload';


// import { ElTooltip, ElPopover } from "element-plus";

const GlobalComponents = {
  install(app) {
    app.component('Select2', Select2)
    app.component('multiselect', Multiselect)
    app.component("VeeValidateForm", Form);
    app.component("Datepicker", Datepicker);
    app.component("VueTelInput", VueTelInput);
    app.component("Field", Field);
    app.component("ErrorMessage",ErrorMessage);
    app.component("badge", Badge);
    app.component("base-alert", BaseAlert);
    app.component("base-button", BaseButton);
    app.component("base-checkbox", BaseCheckbox);
    app.component("base-dropdown", BaseDropdown);
    app.component("base-header", BaseHeader);
    app.component("base-input", BaseInput);
    app.component("base-nav", BaseNav);
    app.component("base-pagination", BasePagination);
    app.component("base-progress", BaseProgress);
    app.component("base-switch", BaseSwitch);
    app.component("base-radio", BaseRadio);
    app.component("base-radio-group", BaseRadioGroup);
    app.component("custom-radio-group", CustomRadioGroup);
    app.component("base-table", BaseTable);
    // app.component("modal", Modal);
    app.component("stats-card", StatsCard);
    app.component("upload-file", UploadFile);
    app.component("upload-image", UploadImage);

    app.component('my-upload', myUpload)
    // app.component("tab-pane", TabPane);
    // app.component("tabs", Tabs);
    // app.use(ElTooltip);
    // app.use(ElPopover);
  },
};

export default GlobalComponents;

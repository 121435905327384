<template>

      <div>
     <carousel autoplay="2000" :items-to-show="1">
    <slide v-for="slide in 1" :key="slide">

      <div class="info logincarouseltext">
                      <div class="animated-text text-left">
                        <h3>
                          Welcome to {{title}} MMS<br />
                          Member Management System
                        </h3>
                      </div>
                      <p class="color-d5 text-left">All-in-one for Sports Organizations</p>
                </div>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
      </div>

</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import axios from "axios";
export default {
    name: "TextSlider",
    components: {
    Carousel,
    Slide,
    Pagination,
  },
   data() {
    return {
      title:'iSportz'
    };
  },
  mounted() {
      axios.get(this.basePath+'api/getProperties/title')
          .then((res) => {
            if(res.data.properties)
            this.title = res.data.properties;
          })
          .catch((error) => {

          }).finally(() => {
      });
  }

}
</script>

<style>

</style>